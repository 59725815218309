import { Injectable, Injector } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api-services/api.service';
import { environment } from 'src/environments/environment';
import { DATETIMEFORMART, CalendarEvent, Place, SearchRequest } from '../interfaces/interfaces';
import { AvailablePlacesStore } from '../shared/available-places/available-places.store';
import * as moment from 'moment';
import { AvatarService } from './avatarService/avatar.service';
import { FloorplanSearchService } from '../shared/search/floorplan-search.service';
import { PlacesService } from './placesService/places.service';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  private connection: signalR.HubConnection;
  private MyBookingsConnection: signalR.HubConnection;
  public places$ = new BehaviorSubject<Place[]>([]);
  public searchPlaces$= new Subject<any>();
  public events$ = new BehaviorSubject<CalendarEvent[]>([]);
  public favoritePlaces$=new Subject<any>();
  public searchFavoritePlaces$=new Subject<any>();
  public NotFavoritePlcaes$=new Subject<any>();
  public errorMessage$=  new Subject<any>();
  public loading$=new BehaviorSubject<boolean>(false);
  public isMyBookingFinished$=new BehaviorSubject<boolean>(true);
  public isInvitationsFinished$=new BehaviorSubject<boolean>(true);
  public myBookingError$=new BehaviorSubject<boolean>(false);
  public placesTemp:Place[] = [];
  public AvailabePlaces$ = new Subject<any>();
  private placesService:PlacesService;
  public isRedisChached:boolean;
  public syncingBookings : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSideMenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private apiService: ApiService, private oidcSecurityService: OidcSecurityService, private injector: Injector, private avatarService: AvatarService, private floorplanSearchService: FloorplanSearchService) {

  }
  public createConnectionAndInvokeGetCall(invokeName:string){
    this.oidcSecurityService.getAccessToken().subscribe(token => {
      this.connection = new signalR.HubConnectionBuilder().withUrl(environment.baseUrl + "/SparoConnectionHub", { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      this.connection.start().then(() => {
        console.log('connection started');
        //calling the API function
        this.connection.invoke(invokeName).catch(err=> console.log('invokation canceled')
        );
        this.errorListener();
      });
    })
  }
  public createConnectionAndInvokeGetMyEvents(invokeName:string){
    this.oidcSecurityService.getAccessToken().subscribe(token => {
      this.MyBookingsConnection = new signalR.HubConnectionBuilder().withUrl(environment.baseUrl + "/SparoConnectionHub", { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      this.MyBookingsConnection.start().then(() => {
        console.log('connection started');
        //calling the API function
        this.MyBookingsConnection.invoke(invokeName).catch(err=> console.log('invokation canceled')
        );
        this.MyBookingsConnectionErrorListener();
      });
    })
  }
  public createConnectionAndInvokeGetCallWithParmetar(invokeName:string,param:string, param2?: any){
    this.oidcSecurityService.getAccessToken().subscribe(token => {
      this.connection = new signalR.HubConnectionBuilder().withUrl(environment.baseUrl + "/SparoConnectionHub", { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      this.connection.start().then(() => {
        console.log('connection started');
        //calling the API function
        this.connection.invoke(invokeName, param, param2).catch(err=> console.log('invokation canceled')
        );
        this.errorListener();
      });
    })
  }

  public createConnectionAndInvokeSeriesSearchCall(invokeName: string, startDate, endDate, placesType, AccuranceDays, interval, dailyType, choosenDate, reccuranceEndDate,isFavoritePlaces:boolean) {
    this.oidcSecurityService.getAccessToken().subscribe(token => {
      this.connection = new signalR.HubConnectionBuilder().withUrl(environment.baseUrl + "/SparoConnectionHub", { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      this.connection.start().then(() => {
        console.log('connection started');
        //calling the API function
        this.connection.invoke(invokeName, startDate, endDate, placesType, AccuranceDays, interval, dailyType, choosenDate, reccuranceEndDate,isFavoritePlaces).catch(err => console.log('invokation canceled')
        );
        this.errorListener();
      });
    })
  }

  searchAvailableOrFavoritePlacesForSeriesEvent(seriesSearchRequest:SearchRequest,isFavoritePlaces : boolean) {
    let reccuranceEndDate = seriesSearchRequest.recurrenceEndDate;
    let repeatEvery = seriesSearchRequest.repeatEvery;
    let selectedWeekDays = seriesSearchRequest.selectedWeekDays;
    let startTimeUTC = seriesSearchRequest.startTimeUTC;
    let endTimeUTC = seriesSearchRequest.endTimeUTC;
    let placeType = seriesSearchRequest.type;
    let recurrenceType = seriesSearchRequest.recurrenceType;    
    let dayOfMonthlyMeeting = moment(seriesSearchRequest.startTimeUTC).set("date", seriesSearchRequest.dayOfMonthlyMeeting).toISOString();
    
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    
    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeSeriesSearchCall('SearchAvailableOrFavoritePlacesForSeriesEvent', startTimeUTC, endTimeUTC, placeType, selectedWeekDays, repeatEvery, recurrenceType, dayOfMonthlyMeeting, reccuranceEndDate,isFavoritePlaces);
    this.loading$.next(true);
    //start listening for "AvailablePlaces"
    if(isFavoritePlaces) {
      this.connection.on('SearchFavoritePlaces', data => {
        if (data != "finish"){
          data.forEach(element => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
          this.searchFavoritePlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp));
        }
        else{
          this.loading$.next(false);
          this.connection.stop().then(() =>
            console.log('connection stopped after finishing the call')
        );
      }     
    });
    } else {
      this.connection.on('SearchAvailablePlaces', data => {
        if (data != "finish"){
          data.forEach(element => {
            this.placesTemp.push(element);
          });
          this.loading$.next(true);
          this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
          this.searchPlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp));
        }
        else{
          this.loading$.next(false);
          this.connection.stop().then(() =>
            console.log('connection stopped after finishing the call')
        );
      }     
    });
  }
}
  
  public createConnectionAndInvokeSearchCall(invokeName:string, startTimeUTC,endTimeUTC,type){
    this.oidcSecurityService.getAccessToken().subscribe(token => {
      this.connection = new signalR.HubConnectionBuilder().withUrl(environment.baseUrl + "/SparoConnectionHub", { accessTokenFactory: () => token }).withAutomaticReconnect().build();
      this.connection.start().then(() => {
        console.log('connection started');
        //calling the API function
        this.connection.invoke(invokeName,startTimeUTC,endTimeUTC,type).catch(err=> console.log('invokation canceled')
        );
        this.errorListener();
      });
    })
  }

  public getAvailablePlaces() {
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeGetCall('getAvailablePlace');
    this.loading$.next(true);
    //start listening for "AvailablePlaces"
    this.connection.on('AvailablePlaces', data => {
      if (data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.places$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else{
       this.loading$.next(false);
        this.connection.stop().then(() =>{
        console.log('connection stopped after finishing the call')
        }
      );
      }     
    });
  }

  public searchAvailablePlaces(searchRequest){
    this.placesTemp=[];
     if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    //Creating connection and invoking searchAvailablePlace
    this.createConnectionAndInvokeSearchCall("SearchAvailablePlaces",searchRequest.startTimeUTC,searchRequest.endTimeUTC,searchRequest.type);
    this.loading$.next(true);
    //start listening for SearchAvailablePlaces
    this.connection.on('SearchAvailablePlaces', data => {
      if (data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.searchPlaces$.next(this.placesTemp);
        this.places$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else{
        this.loading$.next(false);
        this.connection.stop().then(() =>
        console.log('connection stopped after finishing the call')
      );
      }     
    });
  }

  
  public getFavoritePlaces(){
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    
    //Creating connection
    this.createConnectionAndInvokeGetCall('GetFavoritePlaces');
    this.loading$.next(true);
    //start listening and invoking FavoritePlaces
    this.connection.on('FavoritePlaces',data=>{
      if(data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.favoritePlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else{
        this.loading$.next(false);
        this.connection.stop().then(()=> 
        console.log('connection stopped after finishing the call')
        );  
      }
      
  }) 
  }

  public getAvaialbePlacesNew(isFavorite,isForceRefresh = false){
    this.placesService = this.injector.get(PlacesService);
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    //Creating connection
    this.createConnectionAndInvokeGetCallWithParmetar('GetAvailablePlacesWithCacheAndForceRefresh',isFavorite, isForceRefresh);
    this.placesTemp=[];

    this.loading$.next(true);
    //start listening and invoking FavoritePlaces
    this.connection.on('FavoritePlaces', data => {
      if (data != "finish") {
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp = this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.AvailabePlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else {
        this.placesService.lastupdateFavorite = moment();
        this.placesService.fetchFavoriteIsFinished = true;
        this.loading$.next(false);
        this.connection.stop().then(() =>
          console.log('connection stopped after finishing the call')
        );
      }
    }); 
    this.connection.on('AvailablePlaces', data => {
      if (data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.AvailabePlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else{
        this.placesService.lastupdatePlaces = moment();
        this.placesService.fetchAvaialbeIsFinished = true;
       this.loading$.next(false);
        this.connection.stop().then(() =>{
        console.log('connection stopped after finishing the call')
        }
      );
      }     
    });
    this.connection.on('AvailablePlacesCached', data => {
      this.isRedisChached=true;
      if (data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
        });
        this.loading$.next(true);
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.AvailabePlaces$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp))
      }
      else {
        this.placesService.lastupdatePlaces = moment();
        this.placesService.fetchAvaialbeIsFinished = true;
        this.placesService.lastupdateFavorite = moment();
        this.placesService.fetchFavoriteIsFinished = true;
        this.loading$.next(false);
        this.connection.stop().then(() => {
          console.log('connection stopped after finishing the call')
        }
        );
        this.isRedisChached = false;
      }     
    });
  }
  public searchFavoritePlaces(searchRequest){
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    //Creating connection and invoking SearchFavoriteAvailablePlaces
    this.createConnectionAndInvokeSearchCall("SearchFavoriteAvailablePlaces",searchRequest.startTimeUTC,searchRequest.endTimeUTC,searchRequest.type);

    //start listening for SearchFavoritePlaces
    this.connection.on('SearchFavoritePlaces',data=> {
      if(data != "finish"){
        data.forEach(element => {
          this.placesTemp.push(element);
          });     
        this.placesTemp= this.floorplanSearchService.mapPlacesListToItsFloorMap(this.placesTemp);
        this.places$.next(this.avatarService.mapAvatarListToPlaceList(this.placesTemp));
        this.loading$.next(true);
        this.searchFavoritePlaces$.next(this.placesTemp)


      }
      else{
        this.loading$.next(false);
        this.connection.stop().then(()=> 
        console.log('connection stopped after finishing the call')
        );
      }
     
     
    })
  }

  public GetNotFavoritePlaces() {
    this.placesTemp=[];
    if(this.connection != undefined){ //to stop the connection if the previous call didn't finish
      if(this.connection.state == signalR.HubConnectionState.Connected){
        this.connection.stop().then(()=> 
        console.log('connection stopped because the previous function did not finish')
        );
      }
    }
    //Creating connection and invoking getAvailablePlace
    this.createConnectionAndInvokeGetCall('GetNotFavoritePlaces');
    this.loading$.next(true);
    //start listening for "AvailablePlaces"
    this.connection.on('NotFavoritePlaces', data => {
      this.NotFavoritePlcaes$.next(this.avatarService.mapAvatarListToPlaceList(data))
      this.loading$.next(false);
      this.connection.stop().then(() =>
      console.log('connection stopped after finishing the call')
    );
    });
  }

  public GetEventsAndSaveToLocalStorge() {
    this.myBookingError$.next(false);
    let isLocalCached = this.checkIfLocalStorgeExist("myBookings");
    this.placesService = this.injector.get(PlacesService);
    if (this.MyBookingsConnection?.state == signalR.HubConnectionState.Connected) { 
      return
    }
    let fetchedEvents: CalendarEvent[] = [];
    let myBookingsChunksCounter = 0;
    let invitationsChunksCounter = 0;
    if(isLocalCached) this.syncingBookings.next(true)
    //Creating connection and invoking GetEvents
    this.createConnectionAndInvokeGetMyEvents('GetEvents');
    this.loading$.next(true);
    !isLocalCached && this.isMyBookingFinished$.next(false);
    !isLocalCached && this.isInvitationsFinished$.next(false);
    //start listening for "Events" Call Back
    this.MyBookingsConnection.on('Events', data => {
      if (data == "finish") {
        this.loading$.next(false);
        this.MyBookingsConnection.stop().then(() =>
          console.log('connection stopped after finishing the call')
        );
        this.placesService.lastupdateMyBooking = moment();
        this.placesService.fetchMyBookingsIsFinished = true;
        this.saveToLocalStorge("myBookings", this.events$.value)
        this.placesService.MyBooking$.next(this.events$.value)
        if(isLocalCached) this.syncingBookings.next(false)
        return;

      }
      if (data == "myBookings") {
        (++myBookingsChunksCounter == 7 || isLocalCached) ? this.isMyBookingFinished$.next(true) : this.isMyBookingFinished$.next(false)
      }

      if (data == "invitations") {
        (++invitationsChunksCounter == 7 || isLocalCached) ? this.isInvitationsFinished$.next(true) : this.isInvitationsFinished$.next(false)
      }
      data.forEach(element => {
        fetchedEvents.push(element);
      });
      //his.loading$.next(true);
      fetchedEvents = fetchedEvents.sort((a, b) => this.compareStartTime(a, b))
      this.events$.next(this.avatarService.mapAvatarListToEventList(fetchedEvents))
      this.placesService.syncMyBookingData(this.events$.value);


    });
  }
  public saveToLocalStorge(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  }
  public checkIfLocalStorgeExist(key) {
    let localData = localStorage.getItem(key);
    return localData == null ? false : true
  }
  compareStartTime(event1: CalendarEvent, event2: CalendarEvent): number {
    // Convert startTime strings to Date objects for comparison
    const startTime1 = new Date(event1.startTime);
    const startTime2 = new Date(event2.startTime);

    // Compare the startTime values
    if (startTime1 < startTime2) {
        return -1;
    } else if (startTime1 > startTime2) {
        return 1;
    } else {
        return 0;
    }
}
  public errorListener(){
    this.connection.on('ErrorCallBack',error=>{
      this.connection.stop().then(err=>console.log("there was an error in the server side:",error))
      this.errorMessage$.next(error)
    })
  }
  public MyBookingsConnectionErrorListener(){
    this.MyBookingsConnection.on('ErrorCallBack',error=>{
      this.MyBookingsConnection.stop().then(err=>console.log("there was an error in the server side with fetching the event:",error))
      this.myBookingError$.next(error)
    })
  }

  public stopAllListener(){
    console.log("stop All listener")
    this.connection.off("AvailablePlaces");
    this.connection.off("SearchAvailablePlaces");
    this.connection.off("FavoritePlaces");
    this.connection.off("SearchFavoritePlaces");
  }

  public stopConnection(){
    if(this.connection?.state == "Connected"){
      this.connection.stop();
    }
  }
}

import { AfterContentInit, Component, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { EVENTPAGETYPES } from 'src/app/event/event.page';
import { SearchService } from '../search/search.service';
import { AvailablePlacesStore } from './available-places.store';
import { ApiService } from 'src/app/services/api-services/api.service';
import { finalize, map, of, Subject, switchMap } from 'rxjs';
import { HttpCancleService } from 'src/app/services/httpCancelService/http-cancle.service';
import { WebSocketService } from 'src/app/services/web-socket.service';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import { FloorplanSearchService } from '../search/floorplan-search.service';
import { ActionSheetController, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlacesService } from 'src/app/services/placesService/places.service';
import { PlacesSettingsComponent } from '../places-settings/places-settings.component';
import { PlacesSettingsService } from 'src/app/services/places-settings-service/places-settings.service';
// import { PlacesSettingsComponent } from '../places-settings/places-settings.component';

const localStoragePlaceTagKey = "placeTag"
@Component({
  selector: 'app-available-places',
  templateUrl: './available-places.component.html',
  styleUrls: ['./available-places.component.scss'],
})
export class AvailablePlacesComponent implements OnInit {
  isFav = false;
  roomsSpacesList;
  roomsSpacesListFull;
  roomsSpacesListLoading$ = this.availablePlacesStore.roomsPlacesListLoading$;
  roomsSpacesListError$ = this.availablePlacesStore.roomsPlacesListError$;
  isLoading = false;
  query: string = "";
  type: string = null;

  constructor(private availablePlacesStore: AvailablePlacesStore,
    private router: Router,
    private searchService: SearchService,
    private toastService: ToastService,
    private httpsCancelService: HttpCancleService,
    private apiService: ApiService,
    private webSocketService: WebSocketService,
    private floorplanSearchService:FloorplanSearchService,
    private actionSheetController: ActionSheetController,
    private translateService: TranslateService,
    private placesService: PlacesService,
    private popoverController : PopoverController,
    // private modalCtrl : ModalController,
    private placeSettingsService : PlacesSettingsService,


  ) {
    this.availablePlacesStore.filteredList$.subscribe(list => {
      this.roomsSpacesList = list;
      this.roomsSpacesListFull = list;
      this.filter();
    });
    this.placesService.places$.subscribe(places=>{
      this.roomsSpacesList = placesService.filterPlacesBasedOnSettingsCriteria(places,this.placeSettingsService.filterCriteria);
      this.roomsSpacesListFull = placesService.filterPlacesBasedOnSettingsCriteria(places,this.placeSettingsService.filterCriteria);; 
      this.filter();

    })
    this.webSocketService.searchPlaces$.subscribe(places=> {
      this.roomsSpacesList = placesService.filterPlacesBasedOnSettingsCriteria(places,this.placeSettingsService.filterCriteria);
      this.roomsSpacesListFull = placesService.filterPlacesBasedOnSettingsCriteria(places,this.placeSettingsService.filterCriteria);;
      this.filter();
    })
    this.placeSettingsService.placeTag$.subscribe(placeTag=> {
      this.filter(placeTag)
    })
    let localStoragePlaceTag = localStorage.getItem(localStoragePlaceTagKey);
    if(localStoragePlaceTag) this.type = (JSON.parse(localStoragePlaceTag));
  }

  ngOnInit() {
  }

  ionViewDidEnter(){
    this.trackAndMapPlacesToFloorplans();
  }

 

  async openPlacesSettingsPopover(e: Event) {
      const popover = await this.popoverController.create({
        component: PlacesSettingsComponent,
        event: e,
        cssClass: "settings-modal",
        size: "auto",
        showBackdrop: true
      });
      popover.style.cssText = '--width: 300px;';

      await popover.present();
  }

  // async openPlacesSettingsModal(e: Event) {
  //   const modal = await this.modalCtrl.create({
  //     component: PlacesSettingsComponent,
  //     initialBreakpoint: 0.35,
  //     cssClass:"settings-modal",
  //   });
  //   modal.present();

   
  // }

  async placeNotAvaliableHandler() {
    await this.toastService.openInfoToastAsync('placesNotAvailable');
  }

  // Searchbar Logic Places
  findPlaceByDisplayName(event) {
    this.query = event.target.value.toLowerCase();
    this.filter();
  }

  filter(type: string = 'default') {
    if (type != 'default') {
      this.type = type;
    }
    this.roomsSpacesList = this.roomsSpacesListFull.filter(
      (place) =>
      (this.query == ''
          ? true
          : place.displayName.toLowerCase().indexOf(this.query.toLowerCase()) >
          -1) &&
        (this.type == '' || this.type == null || this.type == 'default'
          ? true
          : place.tag == null ? false
          : place.tag.toLowerCase() == this.type.toLowerCase()
          )
    );
  }

  doRefresh(event) {
    this.httpsCancelService.cancelPendingRequests()
    if(this.searchService.searchRequestState.searchRequest != null){
      this.webSocketService.searchAvailablePlaces(this.searchService.searchState$.getValue().searchRequest);
      this.availablePlacesStore.searchAvailablePlaces(this.searchService.searchState$.getValue().searchRequest);
    }
    else{
      this.placesService.getAvailablePlaces(false, true);
    }

    event.target.complete();
  }
 

  private trackAndMapPlacesToFloorplans() {
    this.floorplanSearchService.floorplanInfoList$.subscribe(floorplanList => {
      if ((this.roomsSpacesList.filter(place => place.floorMap != undefined).length == 0)) {
        this.roomsSpacesList = this.floorplanSearchService.mapPlacesListToItsFloorMap(this.roomsSpacesList);
      }
    });
  }
}




import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  IonDatetime,
  Platform,
  PopoverController,
  ToastController,
} from '@ionic/angular';

import * as moment from 'moment';
import {
  DATETIMEFORMART, 
  SearchRequest,
} from 'src/app/interfaces/interfaces';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { AvailablePlacesStore } from 'src/app/shared/available-places/available-places.store';
import { SearchService } from '../search.service';
import { TranslateService } from '@ngx-translate/core';
import { StringInfoMessageService } from 'src/app/services/string-info-message/string-info-message.service';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';
@Component({
  selector: 'app-searchpopover',
  templateUrl: './searchpopover.component.html',
  styleUrls: ['./searchpopover.component.scss'],
  providers: [AvailablePlacesStore],
})
export class SearchpopoverComponent implements OnInit, OnDestroy {
  @ViewChild('endConfirm') endConfirm: IonDatetime;
  @ViewChild('recurrenceEnddateHash') recurrenceEnddateHash: IonDatetime;
  resourceTypesList = [
    { type: 'all', name: 'all' },
    { type: 'room', name: 'room' },
    { type: 'workspace', name: 'workspace' },
  ];
  selectedResourceType = 'all';
  weekdays: { value: string; active: boolean; stringName: string }[] = [
    { value: 'Sun', active: false, stringName: 'weekdayName.Sun' },
    { value: 'Mon', active: false, stringName: 'weekdayName.Mon' },
    { value: 'Tue', active: false, stringName: 'weekdayName.Tue' },
    { value: 'Wed', active: false, stringName: 'weekdayName.Wed' },
    { value: 'Thu', active: false, stringName: 'weekdayName.Thu' },
    { value: 'Fri', active: false, stringName: 'weekdayName.Fri' },
    { value: 'Sat', active: false, stringName: 'weekdayName.Sat' },
  ];
  minimalStartTime;
  isAllDay: boolean = false;
  minimalEndTime;
  searchRequest: SearchRequest;
  // seriesSearchRequest1: SearchRequest = {
  //   recurrenceType: "none",
  //   repeatEvery: 1,
  //   recurrenceEndDate: moment().add(1, 'day').format(DATETIMEFORMART),
  //   selectedWeekDays: [],
  //   dayOfMonthlyMeeting: moment().date(),
  //   startTimeUTC: '',
  //   endTimeUTC: '',
    
  // };
  locale
  disableApply: boolean = false;
  searchtState;
  isIOS: boolean = false;
  initalSearchRequest: SearchRequest;
  recureenceMinDate = moment().format();
  checkDatesForDateTimeHTML = (dateString: string) => {
    return moment(dateString).isAfter(moment().subtract(1, 'd'));
  };

  constructor(
    private popoverController: PopoverController,
    private translateService: TranslateService,
    private searchService: SearchService,
    public translate: LanguageService,
    public platform: Platform,
    public toastController: ToastController,
    public stringInfoMessageService: StringInfoMessageService,
    public dateTimeFormatService: DatetimeFormatService
  ) {
    let minutesToSubtract = 0;

    if (moment().minutes() < 30) {
      minutesToSubtract = moment().minutes();
    } else {
      minutesToSubtract = moment().minutes() - 30;
    }
    this.searchRequest = {
      startTimeUTC: moment()
        .subtract(minutesToSubtract, 'minutes')
        .utc()
        .format(DATETIMEFORMART),
      endTimeUTC: moment()
        .subtract(minutesToSubtract, 'minutes')
        .utc()
        .add(1, 'hours')
        .format(DATETIMEFORMART),
      type: this.selectedResourceType,
      localStartTimeValue: moment()
      .subtract(minutesToSubtract, 'minutes')
      .format(DATETIMEFORMART),
      localEndTimeValue: moment()
      .subtract(minutesToSubtract, 'minutes')
      .add(1, 'hours')
      .format(DATETIMEFORMART),
      recurrenceType: "none",
      repeatEvery: 1,
      recurrenceEndDate: moment().add(1,"day").format(DATETIMEFORMART),
      selectedWeekDays: [],
      dayOfMonthlyMeeting: moment().date(),
    };
    this.setSearchRequest();
  }

  ngOnDestroy(): void {}

  ngOnInit() {
    if (this.platform.is('ios')) this.isIOS = true;
    if (this.initalSearchRequest != null) {
        this.searchRequest = {
          startTimeUTC: this.initalSearchRequest.startTimeUTC,
          endTimeUTC: this.initalSearchRequest.endTimeUTC,
          type: this.initalSearchRequest.type,
          localStartTimeValue: this.initalSearchRequest.localStartTimeValue,
          localEndTimeValue: this.initalSearchRequest.localEndTimeValue,
          recurrenceType: this.initalSearchRequest.recurrenceType,
          repeatEvery: this.initalSearchRequest.repeatEvery,
          selectedWeekDays: this.initalSearchRequest.selectedWeekDays,
          recurrenceEndDate: this.initalSearchRequest.recurrenceEndDate,
        }
       this.searchRequest.selectedWeekDays?.forEach((weekdayIndex) => {
          this.weekdays.find(
            (weekday) =>
               weekday.value == moment().day(weekdayIndex).format('ddd')
          ).active = true;
        });
      }
    }

  filterRoomsSpacesList(event) {
    let type = event.target.value;
    if (type == '') {
      type = null;
    }
    this.searchRequest.type = type;
  }

  private setSearchRequest() {
    if (this.searchService.searchState$.getValue().searchRequest != null) {
      this.searchRequest =
        this.searchService.searchState$.getValue().searchRequest;
      this.selectedResourceType =
        this.searchService.searchState$.getValue().searchRequest.type;
      this.isAllDay = this.searchRequest.isAllDay;
    }
    this.minimalStartTime = moment().format('YYYY-MM-DDTHH:mm:ss');
    this.minimalEndTime = moment(this.minimalStartTime)
      .add(5, 'minutes')
      .format('YYYY-MM-DDTHH:mm:ss');
  }

  allDayToogle() {
    this.isAllDay = !this.isAllDay;
    if (this.isAllDay) {
      this.searchRequest.startTimeUTC = moment(this.searchRequest.startTimeUTC)
        .set({ hour: 0, minute: 0 })
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localStartTimeValue = moment(
        this.searchRequest.localStartTimeValue
      )
        .set({ hour: 0, minute: 0 })
        .format(DATETIMEFORMART);
      this.searchRequest.endTimeUTC = moment(this.searchRequest.endTimeUTC)
        .set({ hour: 23, minute: 59 })
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(
        this.searchRequest.localStartTimeValue
      )
        .set({ hour: 23, minute: 59 })
        .format(DATETIMEFORMART);
    } else {
      this.searchRequest.startTimeUTC = moment(this.searchRequest.startTimeUTC)
        .set({ hour: moment().hours(), minute: 0 })
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localStartTimeValue = moment(
        this.searchRequest.localStartTimeValue
      )
        .set({ hour: moment().hours(), minute: 0 })
        .format(DATETIMEFORMART);
      this.searchRequest.endTimeUTC = moment(this.searchRequest.endTimeUTC)
        .set({ hour: moment().hours(), minute: 0 })
        .add(1, 'hours')
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(
        this.searchRequest.localEndTimeValue
      )
        .set({ hour: moment().hours(), minute: 0 })
        .add(1, 'hours')
        .format(DATETIMEFORMART);
    }
    this.searchRequest.isAllDay = this.isAllDay;
  }

  removeLastTwoCharacters(str) {
    let translation = 'series.';
    if (str == 'Workday' || str == 'Weekly') str = 'weekly';
    if (str == 'AbsoluteMonthly') str = 'monthly';
    return translation + str;
  }

  changeStartTime(event) {
    if (!this.isAllDay) {
      if (moment(event.target.value).isBefore(moment())) {
        let minutesToSubtract = 0;
        if (moment().minutes() < 30) {
          minutesToSubtract = moment().minutes();
        } else {
          minutesToSubtract = moment().minutes() - 30;
        }
        this.searchRequest.startTimeUTC = moment()
          .subtract(minutesToSubtract, 'minutes')
          .utc()
          .format(DATETIMEFORMART);
        this.searchRequest.localStartTimeValue = moment()
          .subtract(minutesToSubtract, 'minutes')
          .format(DATETIMEFORMART);
        this.searchRequest.endTimeUTC = moment(this.searchRequest.startTimeUTC)
          .add(1, 'hours')
          .utc()
          .format(DATETIMEFORMART);
        this.searchRequest.localEndTimeValue = moment(
          this.searchRequest.localStartTimeValue
        )
          .add(1, 'hours')
          .format(DATETIMEFORMART);
      } else {
        this.searchRequest.startTimeUTC = moment(event.target.value)
          .utc()
          .format(DATETIMEFORMART);
        this.searchRequest.localStartTimeValue = moment(
          event.target.value
        ).format(DATETIMEFORMART);
        this.searchRequest.endTimeUTC = moment(event.target.value)
          .add(1, 'hours')
          .utc()
          .format(DATETIMEFORMART);
        this.searchRequest.localEndTimeValue = moment(event.target.value)
          .add(1, 'hours')
          .format(DATETIMEFORMART);
      }
    } else {
      this.searchRequest.startTimeUTC = moment(event.target.value)
        .set({ hours: 0, minute: 0 })
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localStartTimeValue = moment(event.target.value)
        .set({ hours: 0, minute: 0 })
        .format(DATETIMEFORMART);
      this.searchRequest.endTimeUTC = moment(event.target.value)
        .set({ hours: 23, minute: 59 })
        .utc()
        .format(DATETIMEFORMART);
      this.searchRequest.localEndTimeValue = moment(event.target.value)
        .set({ hours: 23, minute: 59 })
        .format(DATETIMEFORMART);
    }
    this.searchRequest.isAllDay = this.isAllDay;
    this.searchRequest.recurrenceEndDate = 
    this.searchRequest.localStartTimeValue;
    this.searchRequest.dayOfMonthlyMeeting = moment(
      event.target.value
    ).date();
    this.checkTimeIfValid();
    this.updateRecurrenceEndDateBasedOnRecurrenceType(event);
  }

  private updateRecurrenceEndDateBasedOnRecurrenceType(event: any) {
    if (this.searchRequest.recurrenceType != 'none') {
      if (this.searchRequest.recurrenceType == 'Daily') {
        this.recurrenceEnddateHash.value = moment(event.detail.value)
        .add(1, 'day')
        .format();
      } else if (this.searchRequest.recurrenceType == 'Weekly') {
        this.recurrenceEnddateHash.value = moment(event.detail.value)
        .add(7, 'day')
        .format();
      } else if (this.searchRequest.recurrenceType == 'Workday') {
        let daysToAdd = 6 - moment(this.searchRequest.localStartTimeValue).day()
        if (daysToAdd == 0) {
          daysToAdd = 7
        }
        this.recurrenceEnddateHash.value = moment(event.detail.value)
          .add(daysToAdd, 'day')
          .format();
      } else if (this.searchRequest.recurrenceType == 'AbsoluteMonthly') {
        this.recurrenceEnddateHash.value = moment(event.detail.value).add(30, 'day')
        .format();
      }
      this.searchRequest.recurrenceEndDate = moment(
        this.recurrenceEnddateHash.value
      ).format();
      this.recureenceMinDate = moment(
        this.recurrenceEnddateHash.value
      ).format();
    }
  }

  changeRecurrenceEndWhenChangingEndTime(event) {
    this.searchRequest.recurrenceEndDate = moment(
      event.detail.value
    ).format(DATETIMEFORMART);
  }

  changeRepeatEvery(event) {
    this.searchRequest.repeatEvery = event.detail.value;
  }

  handleOccurenceType(){
    if(this.searchRequest.recurrenceType == 'Daily') {
      this.searchRequest.recurrenceEndDate = moment(
        this.searchRequest.localStartTimeValue
      )
      .add(2, 'day')
      .format(DATETIMEFORMART);
    } else if (this.searchRequest.recurrenceType == 'Workday') {
      let daysToAdd =  6 - moment(this.searchRequest.localStartTimeValue).day()      
      if(daysToAdd == 0){
        daysToAdd = 7
      }
      this.searchRequest.recurrenceEndDate = moment(
        this.searchRequest.localStartTimeValue
      )
      .add(daysToAdd, 'day')
      .format(DATETIMEFORMART);
      this.weekdays.forEach((day) => {
        day.active = day.value == 'Sun' || day.value == 'Sat' ? false : true;
      });
    } else if (this.searchRequest.recurrenceType == 'Weekly') {
      this.searchRequest.recurrenceEndDate = moment(
        this.searchRequest.localStartTimeValue
      )
      .add(1, 'week')
      .format(DATETIMEFORMART);
      let dayName = moment(this.searchRequest.localStartTimeValue).format(
        'ddd'
      ); 
      this.weekdays.forEach((day) => {
        day.active = day.value == dayName ? true : false;
      }); 
    } else if (this.searchRequest.recurrenceType == 'AbsoluteMonthly') {
    this.searchRequest.recurrenceEndDate = moment(
      this.searchRequest.localStartTimeValue
    )
    .add(1, 'month')
    .format(DATETIMEFORMART);
    }
    this.recureenceMinDate = moment(
      this.searchRequest.recurrenceEndDate
    ).format();
  }

  changeEndTime(event) {
    this.searchRequest.endTimeUTC = moment(event.target.value)
    .utc()
    .format(DATETIMEFORMART);
    this.searchRequest.localEndTimeValue = moment(event.target.value).format(
      DATETIMEFORMART
    );
    this.searchRequest.recurrenceEndDate = moment(
      event.target.value
    ).format(DATETIMEFORMART);
    this.checkTimeIfValid();
    this.updateRecurrenceEndDateBasedOnRecurrenceType(event);
  }

  private checkTimeIfValid() {
    if (this.searchRequest.endTimeUTC <= this.searchRequest.startTimeUTC) {
      this.disableApply = true;
    } else {
      this.disableApply = false;
    }
  }

  toggleWeekday(weekdayName) {
    if((weekdayName == 'Sun'|| weekdayName == 'Sat') && this.searchRequest.recurrenceType == 'Workday'){
      this.searchRequest.recurrenceType = 'Weekly';
    }
    this.weekdays.forEach((day) => {
      day.value == weekdayName
        ? (day.active = !day.active)
        : (day.active = day.active);
      if (!(weekdayName == 'Sun'|| weekdayName == 'Sat') && !day.active) {
        this.searchRequest.recurrenceType = 'Weekly';
      }
    });
  }

  applySearch() {
    if(this.searchRequest.recurrenceType != 'none') {
      this.searchRequest.localEndTimeValue =
      this.searchRequest.localEndTimeValue;
      this.searchRequest.localStartTimeValue =
      this.searchRequest.localStartTimeValue;
      this.searchRequest.endTimeUTC = this.searchRequest.endTimeUTC;
      this.searchRequest.startTimeUTC = this.searchRequest.startTimeUTC;
      this.searchRequest.type = this.searchRequest.type;
      this.searchRequest.dayOfMonthlyMeeting = moment(
        this.searchRequest.localStartTimeValue
      ).date();
      
      if(
        typeof(this.searchRequest.repeatEvery) != 'number' ||
         this.searchRequest.repeatEvery < 0
        ) {
        return this.wrongInputToast('wrongIntervalInputToast');
      }
      
      const differenceInHours = moment(this.searchRequest.localEndTimeValue).diff(this.searchRequest.localStartTimeValue, 'hours');
      if (differenceInHours > 24 && differenceInHours > 0)
         return this.wrongInputToast('wrongHoursInputToast');

      if (
        this.searchRequest.recurrenceType == 'Workday' || 
        this.searchRequest.recurrenceType == 'Weekly'
      ) {
        this.searchRequest.recurrenceType = 'Weekly';
        if(!this.weekdays.find((item) => item.active == true))
          return this.wrongInputToast('noWeekdaysSelectedToast');
        this.searchRequest.selectedWeekDays = [];
        this.weekdays.forEach((day)=> {
          if(day.active) {
            const dayIndex = moment().day(day.value).weekday();
            this.searchRequest.selectedWeekDays.push(dayIndex);
          }
        });
      } 
      this.popoverController.dismiss(this.searchRequest, 'applySeries');
    } else {
      this.popoverController.dismiss(this.searchRequest, 'apply');
    }
  }

  async wrongInputToast(errorMessage: string) {
    const wrongInputToast = await this.toastController.create({
      message: this.translateService.instant(errorMessage),
      duration: 5000,
      color: 'danger',
    });
    wrongInputToast.present();
  }
  resetSearch() {
    this.popoverController.dismiss(null, 'reset');
  }
}

import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FloorPlanInfo, Place } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';
import { FloorplanSearchService } from '../search/floorplan-search.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, PopoverController, ToastController } from '@ionic/angular';
import { catchError} from 'rxjs';
import { ToastService } from 'src/app/services/toast-service/toast.service';
import {fabric} from 'fabric'
import { PlacesService } from 'src/app/services/placesService/places.service';

@Component({
  selector: 'app-favorite-map-item',
  templateUrl: './favorite-map-item.component.html',
  styleUrls: ['./favorite-map-item.component.scss'],
})
export class FavoriteMapItemComponent implements OnInit {
  @Input() floorplan: FloorPlanInfo
  isLoading = false
  isItemLoading: boolean;
  linkedResourcesCount = 0
  linkedResources : string[]
  linkedResourcesAsPlaces :Place[]
  mapPreviewPopOverIsOpen = false
  linkedResourcesPopOverIsOpen = false
  canvas: any;
  
  @ViewChild('mapPreviewPopOver') mapPreviewPopOver;
  @ViewChild('linkedResourcesPopOver') linkedResourcesPopOver;
  @ViewChild('floorCanvasPreview', { static: false }) floorCanvasPreview!: ElementRef;

  constructor(private apiService:ApiService,private popoverController : PopoverController,private floorplanSearchService:FloorplanSearchService,private router:Router,private toastController:ToastController,private translateService: TranslateService,private toastService : ToastService,private placesService : PlacesService,private alertController:AlertController) { 
  }

  ngOnInit() { 
    this.linkedResourcesCount = JSON.parse(this.floorplan.floorMapImage).objects.length - 1
    this.linkedResources = JSON.parse(this.floorplan.floorMapImage).objects.map(obj => obj.name)
    this.linkedResources.shift() //to remove the image from the list:/
    this.placesService.places$.subscribe(places=> {
      if(places.length == 0) return
      this.linkedResourcesAsPlaces = places.filter(item=> this.linkedResources.includes(item.emailAddress))
    })
  }

  removeMapFromFavorite(map:FloorPlanInfo) {
    this.isItemLoading = true
    this.apiService.toggleMapFavorite(map._id).pipe(catchError( () =>{
      this.isLoading = false;
      return this.toggleFavMapWentWrongToast();
    })).subscribe( () =>{
        let userPreferences = this.apiService.userPreferences$.value
        userPreferences.userFavoriteMaps = userPreferences.userFavoriteMaps.filter(favMapId=>favMapId != map._id)
        this.apiService.userPreferences$.next(userPreferences)
        this.isItemLoading = false
    })
  }

  openMap(map:FloorPlanInfo) {  
    this.isLoading = true
    if(map.floorMapImage ==null) return this.noFloorplanImageFoundToast()
    this.floorplanSearchService.applySearch(map)
    this.isLoading = false 
    this.router.navigate(["/floor-plan"])
  }

  openMapPreview(event) {
    this.mapPreviewPopOver.event = event;
    this.mapPreviewPopOverIsOpen = true;
    setTimeout(() => {
      this.initAndDrawCanvasWithAvailibility();
    }, 100);
  }
  
  
  async presentMapPreviewInteracionAlert(floorplan) {
    let alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          this.popoverController.dismiss()
        }
      },
      {
        text: 'Open Map',
        role: 'confirm',
        handler: () => {
          this.popoverController.dismiss()
          // this.dismissMapPreview();
          this.openMap(floorplan)

        },
      },
    ];
    const alert = await this.alertController.create({
      header: 'For more detailed view and map interactions \n \n Please click on "Open Map" below.',
      message: '',
      cssClass: "map-interaction-alert",
      buttons: alertButtons,
    });

    await alert.present();
  }

  private initAndDrawCanvasWithAvailibility() {
    this.canvas = new fabric.StaticCanvas(this.floorCanvasPreview.nativeElement);
    this.canvas.loadFromJSON(JSON.parse(this.floorplan.floorMapImage), () => {
      // Set the dimensions of the canvas
      this.canvas.setWidth(300);
      this.canvas.setHeight(200);

      // Get all objects on the canvas
      const objects = this.canvas.getObjects();

      // Calculate the bounding box of all objects
      const boundingRect = objects.reduce((acc, obj) => {
        const objRect = obj.getBoundingRect();
        acc.left = Math.min(acc.left, objRect.left);
        acc.top = Math.min(acc.top, objRect.top);
        acc.right = Math.max(acc.right, objRect.left + objRect.width);
        acc.bottom = Math.max(acc.bottom, objRect.top + objRect.height);
        return acc;
      }, { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity });

      const boundingWidth = boundingRect.right - boundingRect.left;
      const boundingHeight = boundingRect.bottom - boundingRect.top;

      // Calculate the scaling factors to fit the bounding box within the canvas dimensions
      const scaleFactorWidth = this.canvas.getWidth() / boundingWidth;
      const scaleFactorHeight = this.canvas.getHeight() / boundingHeight;

      // Apply the smaller scaling factor to maintain aspect ratio
      const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);

      // Calculate the offsets to center the content
      const offsetX = (this.canvas.getWidth() - boundingWidth * scaleFactor) / 2;
      const offsetY = (this.canvas.getHeight() - boundingHeight * scaleFactor) / 2;

      // Scale and position each object
      objects.forEach(obj => {
        obj.scaleX *= scaleFactor;
        obj.scaleY *= scaleFactor;
        obj.left = (obj.left - boundingRect.left) * scaleFactor + offsetX;
        obj.top = (obj.top - boundingRect.top) * scaleFactor + offsetY;
        obj.setCoords();
        //recolor shapes based on availibility 
        this.colorShapesBasedOnAvailibility(obj);

      });


      // Adjust the canvas viewport
      this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
      this.canvas.renderAll();
    });
  }

  private colorShapesBasedOnAvailibility(obj: any) {
    let place = this.placesService.places$.value.find((item: any) => item.emailAddress == obj.name);
    if (place) {
      let capacity = undefined;
          if(obj.type != 'group'){      
            if (place.type == "room") {
              capacity = new fabric.Text(place?.capacity?.toString());
            } else {
              capacity = new fabric.Text(place?.availableSpace?.toString() + "/" + place?.capacity?.toString());
            }
            if (place.capacity == undefined || place.availableSpace == undefined) {
              capacity = new fabric.Text('-');
            }
          }
      if (place.isAvailable) {
        if (obj.type != 'group') {
          capacity.isAvailable = true
          obj.isAvailable = true;
          obj.set({
            fill: "rgba(40,176,11,0.2)",
            stroke: "rgba(40,176,11,1)",
            strokeWidth: 4
          });
          capacity.set({
            fill: "rgba(40,176,11,1)",
            originX: "center",
            originY: "center",
            fontSize: 30,
            fontFamily: "Open Sans",
            selectable: false
          });
        } else {
          obj.getObjects()[0].set({
            fill: "rgba(40,176,11,0.2)",
            stroke: "rgba(40,176,11,1)",
            strokeWidth: 4
          });
          
        }
      } else {
        if (obj.type != 'group') {
          capacity.isAvailable = false
          obj.isAvailable = false;
          obj.set({
            fill: "rgba(224,6,28,0.2)",
            stroke: "rgba(224,6,28,1)",
            strokeWidth: 4
          });
          capacity.set({
            fill: "rgba(224,6,28,1)",
            originX: "center",
            originY: "center",
            fontSize: 30,
            fontFamily: "Open Sans",
            selectable: false
          });
        } else {
          obj.getObjects()[0].set({
            fill: "rgba(224,6,28,0.2)",
            stroke: "rgba(224,6,28,1)",
            strokeWidth: 4
          });
        }
      }
      if(capacity) {
      const scaleX = obj.scaleX || 1;
      const scaleY = obj.scaleY || 1;
      const scaleFactor = Math.min(scaleX, scaleY); // Maintain aspect ratio

      // Set the font size scaled to the object's size
      const originalFontSize = 30; // The original font size (you can adjust this based on your design)
      capacity.set({
        fontSize: originalFontSize * scaleFactor < 8 ? 8 : originalFontSize * scaleFactor, // Scale the font size
      });
      // Position the text at the center of the object
      capacity.setPositionByOrigin(obj.getCenterPoint(), "center", "center");

      // Add the text to the canvas
      this.canvas.add(capacity);
      }
    } else {
      obj.set({
        fill: 'rgba(0,0,0,0.5)',
        stroke: '#000',
        strokeWidth: 1,
      });
    }
  }

  dismissMapPreview() {
    console.log("dismissed")
    this.mapPreviewPopOverIsOpen = false
    this.canvas.dispose()
  }


  openLinkedResourcesPopOver(event) {
    this.linkedResourcesPopOver.event = event;
    this.linkedResourcesPopOverIsOpen = true
  }

  dismissLinkedResourcesPopOver() {
    this.linkedResourcesPopOverIsOpen = false

  }


  async toggleFavMapWentWrongToast() { 
    this.isItemLoading = false
    const message =  this.translateService.instant('toggleFavMapWentWrongToast'); 
    const toast = await this.toastController.create({ 
      message, 
      color: "danger",
      duration: 5000, 
      position: 'bottom' 
    }); 
    await toast.present(); 
  } 


  async noFloorplanImageFoundToast() {
    this.isLoading = false
    await this.toastService.openActionToastAsync({
      message: "noFloorplanImageFound",
      duration: 10000,
      color: 'danger',
      cssClass: 'custom-toast-button',
      layout: 'stacked',
      buttons: [
            {
              text: this.translateService.instant("refreshFloorplans"),
              icon:"sync",
              handler: () => {
                this.isLoading=true
                this.floorplanSearchService.getFloorplanInfoList();
                setTimeout(()=> this.isLoading=false, 2000)
              }
            }
          ]
    });
  } 
}
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { Place } from 'src/app/interfaces/interfaces';
import { ApiService } from 'src/app/services/api-services/api.service';
import { WebSocketService } from 'src/app/services/web-socket.service';

@Component({
  selector: 'app-add-favorite-places',
  templateUrl: './add-favorite-places.component.html',
  styleUrls: ['./add-favorite-places.component.scss'],
})
export class AddFavoritePlacesComponent implements OnInit {
  roomsSpacesList;
  roomsSpacesListFull;
  loadingAdd: boolean = false;
  type:string=null;
  search:string="";

  constructor(private apiService: ApiService,private websocket:WebSocketService) { }
//workspace
//room
  ngOnInit() {
    this.websocket.GetNotFavoritePlaces();
    this.websocket.NotFavoritePlcaes$.subscribe(res => {
      this.roomsSpacesList = res;
      this.roomsSpacesListFull = res;
      this.filterPlaces();
    });
  }

  addFavorite(placeFromEvent: any){
    placeFromEvent.loading = true;
    this.apiService.ToggleFavoritePlace(placeFromEvent.emailAddress).pipe(finalize(()=> {
      this.roomsSpacesList = this.roomsSpacesList.filter(place => {
        if(place.emailAddress != placeFromEvent.emailAddress){
          return place;
      }})      
      this.roomsSpacesListFull = this.roomsSpacesListFull.filter(place => {
        if(place.emailAddress != placeFromEvent.emailAddress){
          return place;
      }})
      placeFromEvent.loading = false
    })).subscribe();
  } 

  filterPlaces() {
     
    // if (event.target.value == "") {
    //   this.roomsSpacesList = this.roomsSpacesListFull
    //   return;
    // };
    // const query = event.target.value.toLowerCase();
    if (this.roomsSpacesListFull != undefined){
      this.roomsSpacesList = this.roomsSpacesListFull.filter(place =>
        (this.search == "" ? true : place.displayName.toLowerCase().indexOf(this.search.toLowerCase()) > -1) &&
        (this.type == "" || this.type==null? true : place.type.toLowerCase() == this.type.toLowerCase())
      )
    }
    
  }
  
}

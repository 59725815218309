import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActionSheetController, IonicSlides, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingSlide } from 'src/app/interfaces/interfaces';
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent  implements OnInit {
  swiperModules = [IonicSlides];
  @ViewChild('swiper') swiperRef: ElementRef | undefined;
  swiper?: Swiper
  activeSlide = 0
  slide1: OnboardingSlide = {image: 'assets/onboarding/HXA_SmartOffice.jpg',header:'onboarding.slider1.header',discription:['onboarding.slider1.discription1','onboarding.slider1.discription2']}
  slide2: OnboardingSlide = {image: 'assets/onboarding/loginNew.png',header:'onboarding.slider2.header',discription:['onboarding.slider2.discription1']}
  slide3: OnboardingSlide = {image: 'assets/onboarding/filter.gif',header:'onboarding.slider3.header',discription:['onboarding.slider3.discription1']}
  slide4: OnboardingSlide = {image: 'assets/onboarding/map.jpeg',header:'onboarding.slider4.header',discription:['onboarding.slider4.discription1']}

  slides: Array<OnboardingSlide> = [this.slide1, this.slide2,this.slide3,this.slide4];

  constructor( private modalController: ModalController,private actionSheetController:ActionSheetController,private translateService : TranslateService) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
    if (this.swiperRef) {
    const swiperEl = this.swiperRef.nativeElement;
    swiperEl.initialize();
    this.swiper = swiperEl.swiper;  
    }
  }

  public dismissModal() {
    this.presentCloseOptions(true)
  }

  public goNext(){
    if(this.activeSlide == 3) {
      localStorage.setItem("hideOnboarding",'true')
      this.modalController.dismiss('close')
    }
    this.swiper.slideNext();
 
  }

  public onSlideChange() {
    this.activeSlide = this.swiper.activeIndex
  }

  async presentCloseOptions(triggeredFromCloseButton = false) {
    let closeOnboardingButtons = [
      {
        text: this.translateService.instant('Never show onboarding again'),
        role: 'destructive',
      },
      {
        text: this.translateService.instant('Close onboarding'),
        handler: ()=> {
          this.modalController.dismiss('close')
        }
      },
      {
        text: this.translateService.instant('cancelButton'),
        role: 'cancel',
      },
    ]

    let finishOnboardingButtons = [
      {
        text: this.translateService.instant('Never show onboarding again'),
        role: 'destructive',
      },
      {
        text: this.translateService.instant('Just finish turtorial'),
        handler: ()=> {
          this.modalController.dismiss('close')
        }
      },
      {
        text: this.translateService.instant('cancelButton'),
        role: 'cancel',
      },
    ]
    const actionSheet = await this.actionSheetController.create({
      mode: 'ios',
      buttons: triggeredFromCloseButton ? closeOnboardingButtons : finishOnboardingButtons,
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    if (result.role === 'destructive') {
      localStorage.setItem("hideOnboarding",'true')
      this.modalController.dismiss('close')
    }
   

  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CalendarEvent } from 'src/app/interfaces/interfaces';
import { DatetimeFormatService } from 'src/app/services/datetime-format-service/datetime-format.service';
import { LanguageService } from 'src/app/services/languageservice/language.service';
import { checkIsNextDay } from 'src/app/utils/date-time-utils';
@Component({
  selector: 'app-place-location-info',
  templateUrl: './place-location-info.component.html',
  styleUrls: ['./place-location-info.component.scss'],
})
export class PlaceLocationInfoComponent implements OnInit, OnDestroy {
  @Input() inputPlace: any;
  place: CalendarEvent;
  locale: string;
  @Input() showTimes = false;
  isNextDay: boolean;
  isNoAddress: boolean = false;
  avatar: string | null = null;
  constructor(
    private language: LanguageService,
    private dateTimeFormatService: DatetimeFormatService
  ) {}
  ngOnDestroy(): void {}

  ngOnInit() {
    if (this.inputPlace.place != undefined) {
      this.place = this.inputPlace;
      this.avatar = this.inputPlace.place.avatar;
      this.isNextDay = checkIsNextDay(this.place.startTime, this.place.endTime);

      this.isNoAddress =
        (this.place.place.address.city == null ||
          this.place.place.address.city == '') &&
        (this.place.place.address.street == null ||
          this.place.place.address.street == '') &&
        (this.place.place.address.floor == null ||
          this.place.place.address.floor == '') &&
        (this.place.place.address.zip == null ||
          this.place.place.address.zip == '') &&
        true;
    } else {
      this.avatar = this.inputPlace.avatar;
      this.place = {
        id: this.inputPlace.id,
        place: {
          address: this.inputPlace.address,
          displayName: this.inputPlace.displayName,
          type: this.inputPlace.type,
        },
        startTime: null,
        endTime: null,
        isOrganizer: null,
        subject: null,
        resourceStatus: null,
        attendees: null,
        organizer: {
          emailAddress: null,
          name: null,
        },
        seriesMasterId: null,
        recurrencePattern: null,
        isSeriesActionLoding: false,
        isNewEvent:false,
        editMasterEvent:false,
      };
      this.isNoAddress =
        (this.place.place.address.city == null ||
          this.place.place.address.city == '') &&
        (this.place.place.address.street == null ||
          this.place.place.address.street == '') &&
        (this.place.place.address.floor == null ||
          this.place.place.address.floor == '') &&
        (this.place.place.address.zip == null ||
          this.place.place.address.zip == '') &&
        true;
    }

    this.dateTimeFormatService.selectedDateTimeFormat$.subscribe((format) => {
      this.locale = format;
    });
  }
}

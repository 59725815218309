import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import {
  RecurrencePattern,
  SearchRequest,
} from 'src/app/interfaces/interfaces';
import { DatetimeFormatService } from '../datetime-format-service/datetime-format.service';

@Injectable({
  providedIn: 'root',
})
export class StringInfoMessageService {
  weekdays: string[] = [
    'fullWeekdayName.Sun',
    'fullWeekdayName.Mon',
    'fullWeekdayName.Tue',
    'fullWeekdayName.Wed',
    'fullWeekdayName.Thu',
    'fullWeekdayName.Fri',
    'fullWeekdayName.Sat',
  ];
  constructor(
    private translateService: TranslateService,
    private dateTimeFormatService: DatetimeFormatService
  ) {}
  seriesEventInformationMessageForSeriesSearchRequest(
    seriesSearchRequest: SearchRequest,
    showDaysNames = true
  ) {
    let accuranceDays = [];
    seriesSearchRequest.selectedWeekDays.forEach((element) => {
      accuranceDays.push(this.weekdays[element]);
    });

    let recurrencePattern: RecurrencePattern = {
      accuranceType: seriesSearchRequest.recurrenceType,
      startTime: seriesSearchRequest.localStartTimeValue,
      startDate: seriesSearchRequest.localStartTimeValue,
      endTime: seriesSearchRequest.localEndTimeValue,
      endDate: moment(seriesSearchRequest.recurrenceEndDate).toDate(),
      interval: seriesSearchRequest.repeatEvery,
      dayOfMonth: seriesSearchRequest.dayOfMonthlyMeeting,
      month: 0,
      index: '',
      accuranceDays: accuranceDays,
    };
    return this.seriesEventInformationMessage(recurrencePattern, showDaysNames);
  }
  seriesEventInformationMessage(
    recurrencePattern: RecurrencePattern,
    showDaysNames?
  ) {
    var zeroDate = moment('0001-01-01T00:00:00');
    var informationMessage = this.translate('series.OccursEvery');
    let {
      accuranceType,
      accuranceDays,
      dayOfMonth,
      endDate,
      endTime,
      interval,
      month,
      startDate,
      startTime,
      index,
    } = recurrencePattern;
    switch (accuranceType) {
      case 'Daily':
        interval == 1
          ? (informationMessage += ' ' + this.translate('series.Daily') + ' ')
          : (informationMessage +=
              ' ' +
              this.translate('series.Every') +
              ' ' +
              interval +
              ' ' +
              this.translate('series.Days') +
              ' ');
        break;
      case 'Weekly':
      case 'Workday':
        if (accuranceDays.length == 7 && interval == 1) {
          informationMessage += ' ' + this.translate('series.Daily') + ' ';
        } else {
          interval == 1
            ? (informationMessage +=
                ' ' + this.translate('series.Weekly') + ' ')
            : (informationMessage +=
                ' ' +
                this.translate('series.Every') +
                ' ' +
                interval +
                ' ' +
                this.translate('series.weekly') +
                ' ');
          let accuranceDaysTranslated = accuranceDays.map((day) =>
            this.translate(day)
          );
          let lastAccuranceDay = '';
          if (!showDaysNames) {
            informationMessage +=
              ' ' + this.translate('series.selectedDays') + ' ';
          } else {
            if (accuranceDaysTranslated.length > 1) {
              lastAccuranceDay = accuranceDaysTranslated.pop();
              lastAccuranceDay =
                this.translate('series.And') + ' ' + lastAccuranceDay + ' ';
            }
            informationMessage +=
              ' ' +
              this.translate('series.On') +
              ' ' +
              accuranceDaysTranslated.join(', ') +
              ' ' +
              lastAccuranceDay;
          }
        }
        break;
      case 'AbsoluteMonthly':
        interval == 1
          ? (informationMessage += ' ' + this.translate('series.Monthly') + ' ')
          : (informationMessage +=
              ' ' +
              this.translate('series.Every') +
              ' ' +
              interval +
              ' ' +
              this.translate('series.monthly') +
              ' ');
        informationMessage +=
          ' ' + this.translate('series.onDay') + ' ' + dayOfMonth;
        break;
      case 'RelativeMonthly':
        interval == 1
          ? (informationMessage += ' ' + this.translate('series.Monthly') + ' ')
          : (informationMessage +=
              ' ' +
              this.translate('series.Every') +
              ' ' +
              interval +
              ' ' +
              this.translate('series.monthly') +
              ' ');
        informationMessage +=
          ' ' +
          this.translate('series.On') +
          ' ' +
          index +
          ' ' +
          accuranceDays[0];
        break;
      case 'AbsoluteYearly':
        interval == 1
          ? (informationMessage += ' year ')
          : (informationMessage +=
              ' ' +
              this.translate('series.Every') +
              ' ' +
              interval +
              ' years ');
        informationMessage += ' on day ' + dayOfMonth + ' of ' + month;
        break;
      case 'RelativeYearly':
        interval == 1
          ? (informationMessage += ' year ')
          : (informationMessage +=
              ' ' +
              this.translate('series.Every') +
              ' ' +
              interval +
              ' years ');
        informationMessage +=
          ' on the ' + index + ' ' + accuranceDays[0] + ' of ' + month;
        break;
    }
    informationMessage +=
      ' ' +
      this.translate('series.Starting') +
      ' ' +
      moment(startDate)
        .locale(this.dateTimeFormatService.selectedDateTimeFormat$.value)
        .format('L');
    if (!moment(endDate).isSame(zeroDate)) {
      informationMessage +=
        ' ' +
        this.translate('series.Until') +
        ' ' +
        moment(endDate)
          .locale(this.dateTimeFormatService.selectedDateTimeFormat$.value)
          .format('L');
    }
    informationMessage +=
      ' ' +
      this.translate('series.From') +
      ' ' +
      moment(startTime)
        .locale(this.dateTimeFormatService.selectedDateTimeFormat$.value)
        .format('LT') +
      ' ' +
      this.translate('series.To') +
      ' ' +
      moment(endTime)
        .locale(this.dateTimeFormatService.selectedDateTimeFormat$.value)
        .format('LT');
    return informationMessage;
  }
  translate(words: string) {
    return this.translateService.instant(words);
  }
}

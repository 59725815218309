import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BackService {
  navigationUrls: string[] = [];
  navigationDepth: number = 0;

  constructor(private navController:NavController) {
  }
  
  public navigateBack(){
    if(this.navigationUrls.length == 1){
      this.navController.navigateRoot('/tabs/favorite')
    }
    else{
      --this.navigationDepth;
      this.navigationUrls.pop();
      this.navController.navigateRoot(this.navigationUrls[this.navigationDepth]);
    }
  }

  ngOnInit() {
  }
}

import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { IonicSafeString, ToastOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

interface ExtendedToastOptions extends ToastOptions {

  // If a confirmation action is needed, extend the ToastOptions with an confirmAction Promise.
  // Create an openConfirmToastAsync and use the promise on the confirmation button parameter from the ToastOptions.

  // Optional array of promises representing actions that can be executed sequentially after the toast is dismissed.
  onDismissActions?: (() => Promise<any>)[];
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(
    private translate: TranslateService,
    private toastController: ToastController
  ) { }

  /**
   * Displays a toast with customizable options and handles an optional promise on dismissal.
   * Creates, presents and dismisses the toast directly.
   * 
   * @param options ExtendedToastOptions - Includes all standard ToastOptions from Ionic plus the custom `dismissalActions`.
   * 
   * @remarks
   * The message is automatically translated using the `TranslateService`, ensuring internationalization.
   * If `onDismissActions` are provided, they are executed sequentially after the toast is dismissed.
   */
  public async openActionToastAsync(options: ExtendedToastOptions): Promise<void> {
    const translateMessage: IonicSafeString = this.translate.instant(options.message.toString());

    const toast = await this.toastController.create({
      ...options,
      message: translateMessage
    });

    await toast.present();

    // uses the onDismissActions if there are any
    await toast.onDidDismiss().finally(async () => {
      if (options.onDismissActions && options.onDismissActions.length > 0) {
        for (const action of options.onDismissActions) {
          await action();
        }
      }
      else return;
    });
  }

  /**
   * Displays an error toast with a specified message, position, and duration.
   * Creates, presents and dismisses the toast directly.
   * 
   * @param message String - The message to display in the toast. The message get's directly translated with TranlateService.
   * @param duration Optional Number default 5000 - The duration in milliseconds before the toast is automatically dismissed.
   * @param position Optional 'top' | 'middle' | 'bottom' - The position where the toast will appear.
   * 
   * @remarks
   * The toast will be styled with a 'danger' color to indicate an error.
   */
  public async openErrorToastAsync(message: ExtendedToastOptions["message"], duration: number = 5000, position?: 'top' | 'middle' | 'bottom') {
    await this.openActionToastAsync({
      message: message,
      duration: duration,
      position: position,
      color: "danger"
    });
  }

  /**
   * Displays an informational toast with a specified message, position, and duration.
   * Creates, presents and dismisses the toast directly.
   * 
   * @param message String - The message to display in the toast. The message get's directly translated with TranlateService.
   * @param duration Optional Number default 5000 - The duration in milliseconds before the toast is automatically dismissed.
   * @param position Optional 'top' | 'middle' | 'bottom' - The position where the toast will appear.
   * 
   * @remarks
   * The toast will be styled with a 'medium' color to indicate information.
   */
  public async openInfoToastAsync(message: ExtendedToastOptions["message"], duration: number = 5000, position?: 'top' | 'middle' | 'bottom') {
    await this.openActionToastAsync({
      message: message,
      duration: duration,
      position: position,
      color: "medium"
    });
  }
}

import { Injectable } from '@angular/core';
import { WebSocketService } from '../web-socket.service';
import { CalendarEvent, Place } from 'src/app/interfaces/interfaces';
import { BehaviorSubject, Observable, switchMap, timer } from 'rxjs';
import * as moment from 'moment';
import { AvailablePlacesStore } from 'src/app/shared/available-places/available-places.store';

@Injectable({
  providedIn: 'root',
})
export class PlacesService {
  public places$ = new BehaviorSubject<Place[]>([]);
  public fetchAvaialbeIsFinished:boolean= true;
  public fetchFavoriteIsFinished:boolean= true;
  public fetchMyBookingsIsFinished:boolean= true;
  public favoritePlaces$ = new BehaviorSubject<Place[]>([]);
  public lastupdatePlaces = moment();
  public lastupdateFavorite = moment();
  public lastupdateMyBooking=moment();
  public MyBooking$=new BehaviorSubject<CalendarEvent[]>([]);
  public favoriteListUPN : string[];
  public subscribeForAvailabePlaces$;
  public subscribeForMyBooking$;


  constructor(private webSocketService: WebSocketService) { }


  public getAvailablePlaces(isFavorite: boolean, isForceRefresh: boolean = false) {
    if(this.subscribeForAvailabePlaces$ != undefined){
      this.subscribeForAvailabePlaces$.unsubscribe();
    }
    if (this.NeedToRefreshDataForFavorite(isFavorite) ||
      this.NeedToRefreshDataForAvailabe(isFavorite) || 
      isForceRefresh
      ) {
      isFavorite ? this.PrepareFavoriteVariable() : this.PrepareAvailabeVariable();
      this.webSocketService.getAvaialbePlacesNew(isFavorite, isForceRefresh);
      this.subscribeForAvailabePlaces$ = this.webSocketService.AvailabePlaces$.subscribe(AvailabePlaces => {
        console.log(AvailabePlaces);
      if (this.webSocketService.isRedisChached && !isForceRefresh) {
          this.places$.next(AvailabePlaces);
          this.favoritePlaces$.next(AvailabePlaces);
        }
        else if (isFavorite) {
          this.favoritePlaces$.next(AvailabePlaces);
        }
        else {
          this.places$.next(AvailabePlaces);
        }
      })
    }
    else{
      this.webSocketService.stopConnection();
      this.webSocketService.loading$.next(false);
    }

  }

  public forceGetAvailblePlacesFromCache(isFavorite){
    if(this.subscribeForAvailabePlaces$ != undefined){
      this.subscribeForAvailabePlaces$.unsubscribe();
    }
    isFavorite ? this.PrepareFavoriteVariable() : this.PrepareAvailabeVariable();
      this.webSocketService.getAvaialbePlacesNew(isFavorite);
      this.subscribeForAvailabePlaces$=this.webSocketService.AvailabePlaces$.subscribe(AvailabePlaces => {
        if (this.webSocketService.isRedisChached) {
          this.places$.next(AvailabePlaces);
          let favroiteList = AvailabePlaces.filter(place => {
            return place.isFav
          })
          this.favoritePlaces$.next(favroiteList);
        }
        else if (isFavorite) {
          let favroiteList = AvailabePlaces.filter(place => {
            return place.isFav
          })
          this.favoritePlaces$.next(favroiteList);
        }
        else {
          this.places$.next(AvailabePlaces);
        }
      })
  }

  public getMyBooking(isForceFetching: boolean = false) {
    if (this.subscribeForMyBooking$ != undefined) {
      this.subscribeForMyBooking$.unsubscribe();
    }
    let localStorageData: CalendarEvent[] = JSON.parse(
      localStorage.getItem("myBookings")
    );
    if (localStorageData != null) {
      this.MyBooking$.next(localStorageData)
    }
    else {
      this.subscribeForMyBooking$ = this.webSocketService.events$.subscribe(myEvents => {
        this.MyBooking$.next(myEvents);
      })
    }
    if (this.NeedToRefreshDataForMyBooking() || isForceFetching) {
      this.PrepareMyBookingsVariable();
      this.webSocketService.GetEventsAndSaveToLocalStorge();
    }
    else {
      this.webSocketService.stopConnection();
      this.webSocketService.loading$.next(false);
    }
  }
  public syncMyBookingData(events: CalendarEvent[]) {
    const myBookingEvents = this.MyBooking$.getValue();
    events.forEach(event => {
      if(this.checkIfIsNewEventOrNewStatus(event,myBookingEvents)){
        event.isNewEvent=true;
      }
    }
    );

    const mergedEvents = [...events, ...myBookingEvents];
    // Remove duplicates from the merged array
    const uniqueMergedEvents = mergedEvents.filter((event, index, self) =>
      index === self.findIndex(e => e.id === event.id)
    );
    // Sort the unique merged events
    const finalResult = uniqueMergedEvents.sort((a, b) =>
      this.webSocketService.compareStartTime(a, b)
    );
    // Update the BehaviorSubject with the final result
    this.MyBooking$.next(finalResult);
  }
  public checkIfIsNewEventOrNewStatus(event, myBookingEvents) {
    var currentBooking=myBookingEvents.filter(bookingEvent => bookingEvent.id === event.id);
    if (event.resourceStatus == "TentativelyAccepted" && currentBooking.resourceStatus == "Pending") {
      return false;
    }
    return(!myBookingEvents.some(bookingEvent => bookingEvent.id === event.id))
    || (myBookingEvents.some(bookingEvent => bookingEvent.id === event.id && bookingEvent.resourceStatus != event.resourceStatus))
  }
  public resetIsNewEventValue(){
    const myBookingEvents = this.MyBooking$.getValue();
    myBookingEvents.forEach(element => {
      element.isNewEvent=false;
      
    });
  }
 
  public deleteEventFromMyBooking(EventId:string){
    let currnteMyBooking=this.MyBooking$.value;
    currnteMyBooking = currnteMyBooking.filter((event) => event.id != EventId);;
    this.MyBooking$.next(currnteMyBooking);
    localStorage.setItem("myBookings", JSON.stringify(currnteMyBooking))
  }
  public deleteSeriesEventFromMyBooking(seriesMasterId:string){
    let currnteMyBooking=this.MyBooking$.value;
    currnteMyBooking = currnteMyBooking.filter((event) => event.seriesMasterId != seriesMasterId);
    this.MyBooking$.next(currnteMyBooking);
    localStorage.setItem("myBookings", JSON.stringify(currnteMyBooking))
  }

  public NeedToRefreshDataForFavorite(isFavorite) : boolean{
    let lastupdateInSecondsForFavorite = moment.duration(moment().diff(this.lastupdateFavorite)).asSeconds();
    console.log("favorite:",lastupdateInSecondsForFavorite)
    return isFavorite && (lastupdateInSecondsForFavorite >= 30 || !this.fetchFavoriteIsFinished || this.favoritePlaces$.value.length == 0)
  }
  public NeedToRefreshDataForAvailabe(isFavorite) : boolean{
    let lastupdateInSecondsForAvailable = moment.duration(moment().diff(this.lastupdatePlaces)).asSeconds();
    console.log("available:",(!isFavorite && lastupdateInSecondsForAvailable >= 30), (!isFavorite && !this.fetchAvaialbeIsFinished) , (!isFavorite && this.places$.value.length == 0),lastupdateInSecondsForAvailable);
    return !isFavorite && (lastupdateInSecondsForAvailable >= 30 || !this.fetchAvaialbeIsFinished || this.places$.value.length == 0)
  }
  public NeedToRefreshDataForMyBooking(): boolean {
    let lastupdateInSecondsForMyBooking = moment.duration(moment().diff(this.lastupdateMyBooking)).asSeconds();
    console.log("mybooking:", lastupdateInSecondsForMyBooking >= 60, !this.fetchMyBookingsIsFinished, this.MyBooking$.value.length == 0, lastupdateInSecondsForMyBooking);
    return lastupdateInSecondsForMyBooking >= 60 || !this.fetchMyBookingsIsFinished || this.MyBooking$.value.length == 0
  }
  public PrepareFavoriteVariable(){
    this.fetchFavoriteIsFinished= false;
    this.favoritePlaces$.next([]);
  }
  public PrepareAvailabeVariable(){
    this.fetchAvaialbeIsFinished= false;
    this.places$.next([]);
  }
  public PrepareMyBookingsVariable(){
    this.fetchMyBookingsIsFinished= false;
  }
}

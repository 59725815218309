// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1024px) {
  .places-list {
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/available-places/available-places.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AACE;EACE;IACE,wBAAA;IACA,qCAAA;EAEJ;AACF","sourcesContent":[".spinner-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  @media (min-width:1024px) {\n    .places-list {\n      display: grid !important;\n      grid-template-columns: repeat(3,1fr);\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

/* eslint-disable @typescript-eslint/member-ordering */

import {  Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import {
  ActionSheetController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { catchError, finalize, map, of, repeat, switchMap, take } from 'rxjs';
import { SearchService } from '../shared/search/search.service';
import { ApiService } from '../services/api-services/api.service';
import {
  EventRequest,
  CalendarEvent,
  SearchRequest,
  reminderMinutes,
  AttendeeInformation,
  showAsOptions,
  DATETIMEFORMART,
} from '../interfaces/interfaces';
import { LanguageService } from '../services/languageservice/language.service';
import * as _ from 'lodash';
import { AttendeesModalComponent } from '../modals/attendees-modal/attendees-modal.component';
import { AuthOptions, OidcSecurityService } from 'angular-auth-oidc-client';
import { Browser, OpenOptions } from '@capacitor/browser';
import { ToastService } from '../services/toast-service/toast.service';
import { StringInfoMessageService } from '../services/string-info-message/string-info-message.service';
import { LocalNotificationService } from '../services/localNotification/local-notification.service';
import { DatetimeFormatService } from '../services/datetime-format-service/datetime-format.service';
import { PlacesService } from '../services/placesService/places.service';
import { BackService } from '../services/back-service/back.service';
export const EVENTPAGETYPES = {
  quickBooking: 'quickBooking',
  scheduledBooking: 'scheduledBooking',
  editBooking: 'editBooking',
};

@Component({
  selector: 'app-event',
  templateUrl: './event.page.html',
  styleUrls: ['./event.page.scss'],
})
export class EventPage implements OnInit, OnDestroy {
  place: any;
  isOnPrem: boolean;
  subject = null;
  notCreated = false;
  searchRequest: SearchRequest;
  eventSubmitted = false;
  title: string;
  bookingType: string;
  placeEmailAdress;
  locale;
  isEdited: boolean = false;
  reminutesToSelect = reminderMinutes;
  reminderMinutesBeforeStart: number = 15;
  attendees: AttendeeInformation[] = [];
  isAutoGrow = false;
  isQuickBooking;
  isLoading = false;
  showAsOptions = showAsOptions;
  showAs: string;
  isRestOfDay = true;
  seriesMessage: any;
  isSeriesBooking: boolean;
  weekdays: { value: string, active: boolean, stringName: string }[] = [
    { value: "Sun", active: false, stringName: "weekdayName.Sun" },
    { value: "Mon", active: false, stringName: "weekdayName.Mon" },
    { value: "Tue", active: false, stringName: "weekdayName.Tue" },
    { value: "Wed", active: false, stringName: "weekdayName.Wed" },
    { value: "Thu", active: false, stringName: "weekdayName.Thu" },
    { value: "Fri", active: false, stringName: "weekdayName.Fri" },
    { value: "Sat", active: false, stringName: "weekdayName.Sat" },
  ];
  repeatInterval: any = 1;
  constructor(
    private stringInfoMessageService: StringInfoMessageService,
    private router: Router,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    public searchService: SearchService,
    public actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private platform: Platform,
    private oidcSecurityService: OidcSecurityService,
    private localNotificationService: LocalNotificationService,
    private dateTimeFormatService: DatetimeFormatService,
    private toastController: ToastController,
    private placesService : PlacesService,
    private backService: BackService
    ) {
    this.apiService.userPreferences$.subscribe((preferences) => {
      this.showAs = preferences?.userSettings.bookingStatus;
    });
      if (this.route.snapshot.data.id) {
      this.place = this.route.snapshot.data.id;
      this.placeEmailAdress = this.place.emailAddress;
      this.bookingType = EVENTPAGETYPES.quickBooking;
      this.repeatInterval = this.place.recurrencePattern.interval;
    } else {
      this.initateStateAndObject$().pipe(take(1)).subscribe();
    }
  }

  ngOnInit() {
    this.initDateTimeSelect();
    if (
      (this.bookingType == EVENTPAGETYPES.scheduledBooking &&
       this.searchService.searchRequestState.searchRequest?.recurrenceType != 'none') ||
      (this.bookingType == EVENTPAGETYPES.editBooking && this.place.editMasterEvent == true)
    ) 
        this.isSeriesBooking = true;
    if (this.isSeriesBooking) this.generateSeriesMessage();
    this.dateTimeFormatService.selectedDateTimeFormat$.subscribe((format) => {
      this.locale = format;
    });
  }

  private initateStateAndObject$() {
    return this.route.queryParamMap.pipe(
      map((params) => {
      let place;
      if (params.get('booking')) {
        place = params.get('booking');
      } else {
        place = params.get('place');    
      }
      this.place = JSON.parse(place);
      this.bookingType = params.get('type');
      if (this.place.showAs !== undefined) {
        this.showAs = this.place.showAs;
      }
      
      if (this.place.emailAddress === undefined) {
        this.placeEmailAdress = this.place.place.emailAddress;
      } else {
        this.placeEmailAdress = this.place.emailAddress;
      }
      if (this.place.isOnPrem === undefined) {
        this.isOnPrem = this.place.place.isOnPrem;
      } else {
        this.isOnPrem = this.place.isOnPrem;
      }
      if (this.bookingType == EVENTPAGETYPES.editBooking) {
        this.attendees = this.place.attendees;
      }
      if (this.place.editMasterEvent && this.place.recurrencePattern.accuranceType != 'Weekly' ) {
        let dayName = moment(this.place.recurrencePattern.localStartTimeValue).format("ddd") 
        this.weekdays.forEach(day => {
          day.active = day.value == dayName ? true : false
        }) 
      }
      if (this.place.editMasterEvent) {
        this.repeatInterval = this.place.recurrencePattern.interval;
        let counter = 0;
        let isWeekEnd = false;
        this.place.recurrencePattern.accuranceDays.forEach(day => {
          this.weekdays.find(weekDay => {
            if ((day.toLowerCase().includes(weekDay.value.toLowerCase()))) {
              if (day.toLowerCase().includes('sat') || day.toLowerCase().includes('sun')) {
                isWeekEnd = true;
              }
              weekDay.active = true;
              counter++;
            }
          }
          )
        });
        if (counter == 5 && !isWeekEnd) {
          this.place.recurrencePattern.accuranceType = 'Workday';
        }
        if (isWeekEnd) {
          this.place.recurrencePattern.accuranceType = 'Weekly';
        }
      }
    }));
  }

  private initDateTimeSelect() {  
    switch (this.bookingType) {
      case EVENTPAGETYPES.scheduledBooking:
        this.searchRequest =
          this.searchService.searchState$.getValue().searchRequest;
        this.title = this.translateService.instant('scheduledBooking');
        break;
      case EVENTPAGETYPES.editBooking:
        this.subject = this.place.subject;
        this.searchRequest = {
          endTimeUTC: this.place.endTime,
          startTimeUTC: this.place.startTime,
          localEndTimeValue: moment(this.place.endTime).toISOString(true),
          localStartTimeValue: moment(this.place.startTime).toISOString(true),
        };
        if(this.place.editMasterEvent){
          this.searchRequest.recurrenceStartDate = this.place.recurrencePattern.startDate;
          this.searchRequest.recurrenceEndDate = this.place.recurrencePattern.endDate;
        }
        this.reminderMinutesBeforeStart = this.place.reminderMinutesBeforeStart
        this.title = this.translateService.instant('editBooking');
        break;
      default:
        this.searchRequest = undefined;
        this.title = this.translateService.instant('quickBooking');
        break;
    }
  }

 
  handleOccurenceType(accuranceType){
    this.place.recurrencePattern.accuranceType = accuranceType;
    this.searchRequest.recurrenceType = accuranceType;
    if( this.searchRequest.recurrenceType == "Daily") {
      if (moment(this.searchRequest.recurrenceEndDate).diff(this.searchRequest.recurrenceStartDate, 'days') < 2)
       this.searchRequest.recurrenceEndDate = moment(this.searchRequest.recurrenceStartDate).add(2,"day").format(DATETIMEFORMART)
    }
     else if(this.place.recurrencePattern.accuranceType == "Workday") {
      if (moment(this.searchRequest.recurrenceEndDate).diff(this.searchRequest.recurrenceStartDate, 'days') < 7) {
        let daysToAdd = 6 - moment(this.searchRequest.recurrenceStartDate).day()
        console.log(daysToAdd);
        if (daysToAdd == 0) {
          daysToAdd = 7
        }
        this.searchRequest.recurrenceEndDate = moment(this.searchRequest.recurrenceStartDate).add(daysToAdd, "day").format(DATETIMEFORMART)
      }
      this.weekdays.forEach(day => {
        day.active = day.value == "Sun" || day.value == "Sat" ? false : true 
      }) 
    } 

    else if (this.place.recurrencePattern.accuranceType == "Weekly") {
      if (moment(this.searchRequest.recurrenceEndDate).diff(this.searchRequest.recurrenceStartDate, 'days') < 7)
      this.searchRequest.recurrenceEndDate = moment(this.searchRequest.recurrenceStartDate).add(1,"week").format(DATETIMEFORMART)
    }  
    else if (this.place.recurrencePattern.accuranceType == "AbsoluteMonthly") {
      if (moment(this.searchRequest.recurrenceEndDate).diff(this.searchRequest.recurrenceStartDate, 'days') < 30)
      this.searchRequest.recurrenceEndDate = moment(this.searchRequest.recurrenceStartDate).add(1,"month").format(DATETIMEFORMART)
      this.place.recurrencePattern.dayOfMonth = moment(this.searchRequest.recurrenceStartDate).date();
    }
    this.generateSeriesMessage();
  }

  changeSubject() {
    const eventRequest = this.mapEventRequest();
    this.checkIfEdited(eventRequest);
  }

  setDefaultReminderMinutes(initialValue: any, listValue: any): boolean {
    return initialValue && listValue
      ? initialValue == listValue
      : initialValue == listValue;
  }

  changeReminder(event) {
    this.reminderMinutesBeforeStart = event.target.value;
  }
  changeStatus(event) {
    this.showAs = event.target.value;
  }
  updateSearchRequestAndCheckAvailabillity(value) {
    if(!this.place.editMasterEvent || this.place?.recurrencePattern?.accuranceType == 'none'){
      this.notCreated = false;
      this.searchService.checkAvailabillityRequest$.next(true);
    }
    this.searchRequest = value;
    const eventRequest: EventRequest = this.mapEventRequest();
    if (this.place.editMasterEvent) this.handleOccurenceType(eventRequest.recurrencePattern.accuranceType);
    if(this.place.editMasterEvent && eventRequest.recurrencePattern != null) this.seriesMessage = this.stringInfoMessageService.seriesEventInformationMessage(eventRequest.recurrencePattern)
    this.checkIfEdited(eventRequest)
  if(!this.place.editMasterEvent || this.place?.recurrencePattern?.accuranceType == 'none'){
    this.checkAvailability(eventRequest)
    .pipe(
      finalize(() => 
        this.searchService.checkAvailabillityRequest$.next(false)
    ), 
      catchError((err) => {
      this.openToastIfNoConnection();
      this.notCreated = true;
     throw (this.isLoading = false);
    })
  )
  .subscribe();
  }
}

  updateIsBooking(value) {
    this.isQuickBooking = value;
  }

  private mapEventRequest(): EventRequest {
    let eventRequest: EventRequest = {
      placeEmailAddress: this.placeEmailAdress,
      subject: this.subject ? this.subject : 'HXA ' + this.title,
      startTime: moment(this.searchRequest.localStartTimeValue).format(DATETIMEFORMART),
      endTime: moment(this.searchRequest.localEndTimeValue).format(DATETIMEFORMART),
      reminderMinutesBeforeStart: this.reminderMinutesBeforeStart,
      isOnPrem: this.isOnPrem,
      showAs: this.showAs,
      timeZoneInfo: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    if (
      this.bookingType == EVENTPAGETYPES.scheduledBooking ||
      this.bookingType == EVENTPAGETYPES.editBooking
    ) {
      eventRequest.attendees = this.attendees;
    }
    if (this.isSeriesBooking) {
      if (this.searchService.searchRequestState.searchRequest != null && !this.place.editMasterEvent) {    
        let {dayOfMonthlyMeeting,
          localStartTimeValue,
          localEndTimeValue,
          recurrenceType,
          recurrenceEndDate,
          repeatEvery,
          selectedWeekDays,
        } = this.searchService.searchRequestState.searchRequest
        eventRequest.recurrencePattern = {
          startTime: localStartTimeValue,
          startDate: localStartTimeValue,
          endTime: localEndTimeValue,
          endDate: recurrenceEndDate,
          interval: repeatEvery,
          accuranceDays: selectedWeekDays,
          accuranceType: recurrenceType,
          dayOfMonth: dayOfMonthlyMeeting,
          }
      } else {
        eventRequest.recurrencePattern = this.place.recurrencePattern;
        eventRequest.recurrencePattern.startTime = moment(this.searchRequest.localStartTimeValue).toDate()
        eventRequest.recurrencePattern.startDate =  moment(this.searchRequest.recurrenceStartDate).utc(true).toDate()
        eventRequest.recurrencePattern.endTime =  moment(this.searchRequest.localEndTimeValue).toDate()
        eventRequest.recurrencePattern.endDate =  moment(this.searchRequest.recurrenceEndDate).utc(true).toDate()
        eventRequest.recurrencePattern.accuranceDays=[];
        this.weekdays.forEach((day)=> {
          if(day.active) {
            const dayIndex = moment().day(day.value).weekday().toString();
            eventRequest.recurrencePattern.accuranceDays.push(dayIndex)  
          }
        })
        if(eventRequest.recurrencePattern.accuranceType == 'AbsoluteMonthly') {
          eventRequest.recurrencePattern.dayOfMonth = moment(this.searchRequest.recurrenceStartDate).date();
        }
      }
      }
    return eventRequest;
  }

  private checkAvailability(eventRequest: EventRequest) {
    return this.apiService
      .checkPlaceAvailabilityAndTillEndOfDay(
        this.placeEmailAdress,
        moment(eventRequest.endTime).endOf('day').format(DATETIMEFORMART),
        eventRequest.startTime,
        eventRequest.endTime,
        this.isOnPrem,
        this.place.startTime,
        this.place.endTime,
        this.place.subject,
        Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      .pipe(
        map((res: any) => {
          this.searchService.isBookable$.next(res.available);
          this.isRestOfDay = res.availableTillEndOfDay;
          return res;
        })
      );
  }


  removeLastTwoCharacters(str) {
    let translation='series.';
    if(str == 'Workday' || str == 'Weekly') str = "weekly"
    if(str == 'AbsoluteMonthly') str = "monthly"
    return translation+str;
  }

  DoBookNewEvent() {
    this.isLoading = true;
    this.eventSubmitted = true;
    this.notCreated = false;
    const eventRequest: EventRequest = this.mapEventRequest();
      this.apiService.createCalendarEventNew(eventRequest, this.isOnPrem)
      .pipe(catchError(ex=>{
        console.log(ex);
        if (ex.error == "PlaceNotAvailable"){
          this.searchService.isBookable$.next(false);
        }
        else if(ex.error?.error == "LicenseNotFound"){
          this.openToastIfNoConnection();
          this.eventSubmitted=false;
          this.notCreated=true;
          this.noUserLicenseFoundHandler();
          this.isLoading=false 
        }
        else if (ex.error?.error ==  "LicenseExpired"){
          this.noUserLicenseFoundHandler();
        }
        else{
          this.createEventGeneralErrorHandler();
        }
        this.notCreated = true;
        return ex;
      }),
      finalize(() =>
         {
          this.searchService.checkAvailabillityRequest$.next(false)
          this.isLoading = false
         })
    )
      .subscribe(res=>
        {
          
          this.presentNotification();
        }
      );
    
    
  }

  async presentNotification() {
    let day = moment(this.searchRequest.localStartTimeValue).locale(this.locale).format('L')
    if (day == moment().locale(this.locale).format('L')) {
      day = ''
    } else {
      day += ' '
    }

    const message = this.title + ' ' + day + this.translateService.instant('eventCreatedToastOne')
      + ' ' + moment(this.searchRequest.localStartTimeValue).format('HH:mm') + ' '
      + this.translateService.instant('eventCreatedToastTwo') + ' '
      + moment(this.searchRequest.localEndTimeValue).format('HH:mm') + ' '
      + this.translateService.instant('eventCreatedToastThree');
    if (this.platform.is('android') || this.platform.is('ios')) {
      var isNotificationPermissionExist = await this.localNotificationService.presentLocalNotification(message, "Booking status");
      if (!isNotificationPermissionExist) {
        this.openToastIfSuccess();
      }
    }
    else {
      this.openToastIfSuccess();
    }
    this.isLoading = false
    this.searchService.resetDefaultSearchState()
    this.router.navigate(['tabs/bookings'], { replaceUrl: true })
  }
  doEditBooking() {
    if(!this.weekdays.find(item=>item.active == true) 
      && (this.searchRequest.recurrenceType == 'Workday' || this.searchRequest.recurrenceType == 'Weekly')
      && this.place.editMasterEvent) {
    return this.wrongInputToast("noWeekdaysSelectedToast")
      }
    this.isLoading = true;
    this.eventSubmitted = true;
    this.notCreated = false;
    const eventRequest: EventRequest = this.mapEventRequest();
    this.apiService
      .checkUserLicense()
      .pipe(
        catchError((err) => {
          this.openToastIfNoConnection();
          this.eventSubmitted = false;
          this.notCreated = true;
          throw (this.isLoading = false);
        })
      )
      .subscribe((isValid) => {
        if (isValid) {
          this.checkAvailibilityAndEditBooking(eventRequest);
        } else {
          this.noUserLicenseFoundHandler();
        }
      });
  }

  private checkAvailibilityAndEditBooking(eventRequest: EventRequest) {
    this.searchService.checkAvailabillityRequest$.next(true);
    return this.checkAvailability(eventRequest).pipe(
      switchMap(res => {
        if (res) {
          return this.editBooking(eventRequest);
        } else {
          return this.proceedEditingAction(eventRequest);
        }
      })).subscribe({ next: () => {
        //this.router.navigate(['tabs/bookings'], { replaceUrl: true });
        this.presentNotification();
      } });
  }

  private editBooking(eventRequest: EventRequest) {
    if (this.place.recurrencePattern.accuranceType == 'Workday') {
      this.place.recurrencePattern.accuranceType = 'Weekly';
    } 
    const calenderEvent = this.place as CalendarEvent;
    let eventId = this.place.editMasterEvent == true ? calenderEvent.seriesMasterId : calenderEvent.id
    return this.apiService
    .editCalendarEvent(eventId, eventRequest)
    .pipe(
      map((placeEvent) => {
        // need not to be remapped now
        //calenderEvent.subject = placeEvent.subject;
        //calenderEvent.startTime = placeEvent.startTime;
        //calenderEvent.endTime = placeEvent.endTime;
      }),
      catchError(() => {
        this.notCreated = true;
        return of();
      }),
      finalize(() => {
        this.searchService.checkAvailabillityRequest$.next(false);
        this.eventSubmitted = false;
        this.changeSeriesActionIsLoading(eventId,true)
      })
    );
  }

  changeSeriesActionIsLoading(seriesMasterId, isLoading) {
    let temp =  this.placesService.MyBooking$.value
    temp.forEach((event) => {
      if (event.seriesMasterId === seriesMasterId) {
        event.isSeriesActionLoding = isLoading;
      }
    })
    this.placesService.MyBooking$.next(temp)
    localStorage.setItem("myBookings",JSON.stringify(temp))
  }

  async proceedEditingAction(eventRequest: EventRequest) {
    const actionSheet = await this.actionSheetController.create({
      header: this.translateService.instant('placeNotAvailable'),
      mode: 'ios',
      buttons: [
        {
          text: this.translateService.instant('sendAnyway'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const result = await actionSheet.onDidDismiss();
    if (result.role === 'destructive') {
      return this.editBooking(eventRequest).pipe(take(1)).subscribe();
    }
    this.eventSubmitted = false;
    this.searchService.checkAvailabillityRequest$.next(false);
    return of();
  }

  async doDeleteBooking() {
    const actionSheet = await this.actionSheetController.create({
      header: this.place.editMasterEvent ? this.translateService.instant('deleteSeriesBookingQuestion') :  this.translateService.instant('deleteBookingQuestion'),
      mode: 'ios',
      buttons: [
        {
          text: this.place.editMasterEvent ? this.translateService.instant('deleteSeriesBooking') : this.translateService.instant('deleteBooking'),
          role: 'destructive',
        },
        {
          text: this.translateService.instant('cancelButton'),
          role: 'cancel',
        },
      ],
    });
    await actionSheet.present();
    const condition = await actionSheet.onDidDismiss();
    if (condition.role === 'destructive') {
      this.isLoading = true;
      this.deleteEvent()
        .pipe(
          catchError((err) => {
            this.openToastIfNoConnection();
            this.eventSubmitted = false;
            this.notCreated = true;
            throw (this.isLoading = false);
          })
        )
        .subscribe((isDeleted) => {
          if (isDeleted) {
            this.router.navigate(['/tabs/bookings'], { replaceUrl: true });
          }
        });
    }
  }

  private deleteEvent() {
    this.notCreated = false;
    let eventId = this.place.editMasterEvent == true ? (this.place as CalendarEvent).seriesMasterId: (this.place as CalendarEvent).id
    this.changeSeriesActionIsLoading(eventId,true)
    return this.apiService
      .deleteCalendarEvent(eventId)
      .pipe(
        map((isDeleted) => isDeleted),
        take(1)
      );
  }


  toggleWeekday(weekdayName) {
    if ((weekdayName == 'Sun' || weekdayName == 'Sat') && this.place.recurrencePattern.accuranceType == 'Workday') {
      this.place.recurrencePattern.accuranceType = 'Weekly';
    }
    this.weekdays.forEach((day) => {
      day.value == weekdayName
        ? (day.active = !day.active)
        : (day.active = day.active);
      if (!(weekdayName == 'Sun' || weekdayName == 'Sat') && !day.active) {
        this.place.recurrencePattern.accuranceType = 'Weekly';
      }
    });
  }

  
  async openToastIfSuccess() {
    let day = moment(this.searchRequest.localStartTimeValue)
      .locale(this.locale)
      .format('L');
    if (day == moment().locale(this.locale).format('L')) {
      day = '';
    } else {
      day += ' ';
    }

    const message =
      this.title +
      ' ' +
      day +
      this.translateService.instant('eventCreatedToastOne') +
      ' ' +
      moment(this.searchRequest.localStartTimeValue).format('HH:mm') +
      ' ' +
      this.translateService.instant('eventCreatedToastTwo') +
      ' ' +
      moment(this.searchRequest.localEndTimeValue).format('HH:mm') +
      ' ' +
      this.translateService.instant('eventCreatedToastThree');

    await this.toastService.openActionToastAsync({
      message: message,
      duration: 5000,
      position: 'bottom',
      onDismissActions: [
        async () => (this.isLoading = false),
        async () => this.searchService.resetDefaultSearchState(),
        // async () =>
        //   this.router.navigate(['tabs/bookings'], { replaceUrl: true }),
      ],
    });
  }

  async openToastIfNoConnection() {
    await this.toastService.openInfoToastAsync('notCreatedEvent');
  }

  checkIfEdited(eventRequest: EventRequest) {
    if (this.bookingType == EVENTPAGETYPES.editBooking) {
      if (
        _.isEqual(this.place.startTime, eventRequest.startTime) &&
        _.isEqual(this.place.endTime, eventRequest.endTime) &&
        _.isEqual(this.place.subject, eventRequest.subject)
      ) {
        this.isEdited = false;
      } else {
        this.isEdited = true;
      }
    }
  }

  generateSeriesMessage() {
    if(this.searchRequest != null && this.bookingType != EVENTPAGETYPES.editBooking)
      {
        return this.seriesMessage = this.stringInfoMessageService.seriesEventInformationMessageForSeriesSearchRequest(this.searchRequest)
      }
      return this.seriesMessage = this.stringInfoMessageService.seriesEventInformationMessage(this.place.recurrencePattern)
  }

  handleIntervalChange(event) {
    if(+event > 0 )  this.place.recurrencePattern.interval = +this.repeatInterval;
    this.generateSeriesMessage()
  }

  resetRepeatInterval() {
    if(this.repeatInterval == null || this.repeatInterval == 'e' || this.repeatInterval == 0) {
      this.place.recurrencePattern.interval = 1
      this.repeatInterval = 1
    }
  }
  
  async wrongInputToast(errorMessage:string) {
    const wrongInputToast = await this.toastController.create({
      message: this.translateService.instant(errorMessage),
      duration: 5000,
      color: 'danger'
    });
    wrongInputToast.present();
  }

  ngOnDestroy(): void {
    this.searchService.isBookable$.next(true);
    this.subject = null;
    this.notCreated = false;
    this.searchService.invalidBookingRequest$.next(false);
    this.place = null;
    this.searchRequest = null;
    this.isEdited = false;
  }

  async openAttendeesModal() {
    const modal = await this.modalController.create({
      component: AttendeesModalComponent,
      componentProps: {
        finalChoosenAttendees: this.attendees,
        bookingType: this.bookingType,
        searchRequest: this.searchRequest,
        place: this.place,
      },
    });
    modal.present();
  }

  initAutoGrow() {
    //needs validation
    this.isAutoGrow = true;
  }

  setFocus(field) {
    field.setFocus();
  }

  logout() {
    if (this.platform.is('capacitor')) {
      const authOption: AuthOptions = {
        urlHandler: async (authUrl) => {
          await Browser.open({ url: authUrl });
        },
      };
      this.oidcSecurityService.logoff(undefined, authOption).subscribe(res=>console.log("you loogged out:",res));
    } else {
      this.oidcSecurityService.logoff().subscribe(res=>console.log("you loogged out:",res));
    }
  }

  async noUserLicenseFoundHandler() {
    await this.toastService.openActionToastAsync({
      message: 'noLicenseToast',
      duration: 5000,
      color: 'danger',
      onDismissActions: [async () => this.logout],
    });
  }
  async createEventGeneralErrorHandler() {
    let previousUrl = this.backService.navigationUrls[this.backService.navigationUrls.length - 2];
    await this.toastService.openActionToastAsync({
      message: 'createEventGeneralErrorToast',
      duration: 5000,
      color: 'danger',
      onDismissActions: [async () => this.router.navigate([previousUrl], { replaceUrl: true })],
    });
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 45px;
}

.margin-right {
  margin-right: 16px;
}

ion-text.padding-left {
  padding-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/place-location-info/place-location-info.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACE,kBAAA;AACF;;AAEA;EACI,iBAAA;AACJ","sourcesContent":["ion-icon {\n    font-size: 45px;\n}\n\n.margin-right {\n  margin-right: 16px;\n}\n\nion-text.padding-left {\n    padding-left: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger) !important;
  --border-width: 0 0 3px 0 !important;
}

.user-icon {
  font-size: 50px;
}

ion-item {
  --inner-padding-end: 0;
}

ion-item::part(native) {
  padding-inline-start: 0;
}

.attendee-list-container {
  margin-top: 10px;
}

.attendee-list-item ion-icon.attendee-list-icon {
  margin-left: 5px;
  font-size: 28px;
}

.spinner-background {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toolbar-title {
  padding-inline: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/modals/attendees-modal/attendees-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kDAAA;EACA,oCAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAGA;EACI,uBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAKI;EACI,gBAAA;EACA,eAAA;AAFR;;AAKA;EACI,eAAA;EACA,MAAA;EACA,OAAA;EACA,SAAA;EACA,QAAA;EACA,+BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;;AAKA;EACI,mBAAA;AAFJ","sourcesContent":["ion-toolbar {\n    --border-color: var(--ion-color-danger) !important;\n    --border-width: 0 0 3px 0 !important;\n}   \n\n.user-icon {\n    font-size: 50px;\n}\n\nion-item {\n    --inner-padding-end: 0;\n    \n}\n\nion-item::part(native) {\n    padding-inline-start: 0;\n}\n\n.attendee-list-container{\n    margin-top: 10px;\n}\n\n.attendee-list-item {\n\n    ion-icon.attendee-list-icon {\n        margin-left: 5px;\n        font-size: 28px;\n    }\n}\n.spinner-background {\n    position: fixed;\n    top: 0;\n    left: 0;\n    bottom: 0;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 999;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.toolbar-title {\n    padding-inline: 0px;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AvailablePlacesComponent } from './available-places/available-places.component';
import { SearchComponent } from './search/search.component';
import { SearchpopoverComponent } from './search/searchpopover/searchpopover.component';
import { HeaderComponent } from './header/header.component';
import { EventHeaderComponent } from './event-header/event-header.component';
import { PageSubtitleComponent } from './page-subtitle/page-subtitle.component';
import { MyBookingsItemComponent } from './my-bookings-item/my-bookings-item.component';
import { ListLoadingAnimationComponent } from './list-loading-animation/list-loading-animation.component';
import { DateTimeSelectComponent } from './date-time-select/date-time-select.component';
import { PlaceLocationInfoComponent } from './place-location-info/place-location-info.component';
import { GoBackHeaderComponent } from './go-back-header/go-back-header.component';
import { LanguageSwitchComponent } from './language-switch/language-switch.component';
import { HeaderOnlyComponent } from './header-only/header-only.component';
import { AddFavoritePlacesComponent } from './add-favorite-places/add-favorite-places.component';
import { FloorplanSearchpopoverComponent } from './search/floorplan-searchpopover/floorplan-searchpopover.component';
import { FavoriteMapItemComponent } from './favorite-map-item/favorite-map-item.component';
import { ItemLoadingComponent } from './item-loading/item-loading.component';
import { WorkingHoursComponent } from './working-hours/working-hours.component';
import { DatetimeFormatSettingComponent } from './datetime-format-setting/datetime-format-setting.component';
import { PlaceComponent } from './place/place.component';
// import { PlacesSettingsComponent } from './places-settings/places-settings.component';
import { OnboardingComponent } from './onboarding/onboarding.component';

@NgModule({
  declarations: [
    AvailablePlacesComponent,
    SearchComponent,
    SearchpopoverComponent,
    HeaderComponent,
    EventHeaderComponent,
    PageSubtitleComponent,
    MyBookingsItemComponent,
    ListLoadingAnimationComponent,
    DateTimeSelectComponent,
    PlaceLocationInfoComponent,
    GoBackHeaderComponent,
    LanguageSwitchComponent,
    HeaderOnlyComponent,
    AddFavoritePlacesComponent,
    FloorplanSearchpopoverComponent,
    FavoriteMapItemComponent,
    ItemLoadingComponent,
    WorkingHoursComponent,
    DatetimeFormatSettingComponent,
    PlaceComponent,
    // PlacesSettingsComponent
    OnboardingComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule, TranslateModule.forChild()],
  exports: [
    AvailablePlacesComponent,
    SearchComponent,
    SearchpopoverComponent,
    HeaderComponent,
    EventHeaderComponent,
    PageSubtitleComponent,
    MyBookingsItemComponent,
    ListLoadingAnimationComponent,
    DateTimeSelectComponent,
    PlaceLocationInfoComponent,
    GoBackHeaderComponent,
    LanguageSwitchComponent,
    HeaderOnlyComponent,
    AddFavoritePlacesComponent,
    FloorplanSearchpopoverComponent,
    FavoriteMapItemComponent,
    ItemLoadingComponent,
    WorkingHoursComponent,
    DatetimeFormatSettingComponent,
    PlaceComponent,
    // PlacesSettingsComponent
    
    OnboardingComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}

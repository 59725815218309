// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-color: var(--ion-color-danger) !important;
  --border-width: 0 0 3px 0 !important;
}

ion-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.toolbar-title {
  padding-inline: 0px;
  margin-left: 45px;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 20px;
}
.button-container ion-button {
  width: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/onboarding/onboarding.component.scss"],"names":[],"mappings":"AACA;EACI,kDAAA;EACA,oCAAA;AAAJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AACA;EACI,mBAAA;EACA,iBAAA;AAEJ;;AAAA;EACI,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAGJ;AAFI;EACI,YAAA;AAIR","sourcesContent":["\nion-toolbar {\n    --border-color: var(--ion-color-danger) !important;\n    --border-width: 0 0 3px 0 !important;\n}   \nion-content {\n    display: flex;\n    flex-direction: column;\n    margin-bottom: 10px;\n}\n.toolbar-title {\n    padding-inline: 0px;\n    margin-left: 45px;\n }\n.button-container {\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    margin-top: 10px;\n    margin-bottom: 20px;\n    ion-button {\n        width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
